import {
  Workshop,
  PUT_WORKSHOP,
  PUT_OUTCOME_TYPE,
  PUT_VALUE_OR_FOA,
  REMOVE_VALUE_OR_FOA,
  Outcomes,
  OutcomeType,
  ValueOrFoa
} from '@qwealth/qdata';

interface IWorkshopState {
  initializedWorkshop: boolean;
  workshop: Workshop;
}

const initialState: IWorkshopState = {
  initializedWorkshop: false,
  workshop: {
    id: 0,
    householdQID: '',
    isPrimary: 1,
    workshopPlanDetails: [],
    workshopToValueOrFOA: [],
    outcomes: {}
  }
};

export default (state = initialState, action: any): IWorkshopState => {
  switch (action.type) {
    case PUT_WORKSHOP: {
      return { ...state, initializedWorkshop: true, workshop: action.payload };
    }
    case PUT_OUTCOME_TYPE: {
      const incomingOutcomes: Outcomes = action.payload;
      const outcomeType = Object.keys(incomingOutcomes)[0] as OutcomeType;
      const allOutcomes: Outcomes = { ...state.workshop.outcomes, ...incomingOutcomes };
      if (!incomingOutcomes[outcomeType]) {
        delete allOutcomes[outcomeType];
      }
      return {
        ...state,
        workshop: {
          ...state.workshop,
          outcomes: allOutcomes
        }
      };
    }
    case PUT_VALUE_OR_FOA: {
      const newValueOrFoa: ValueOrFoa = action.payload;
      let valueOrFoaList = state.workshop.workshopToValueOrFOA.filter(valueOrFoa => valueOrFoa.id !== newValueOrFoa.id);
      valueOrFoaList = [...valueOrFoaList, newValueOrFoa];
      return {
        ...state,
        workshop: {
          ...state.workshop,
          workshopToValueOrFOA: valueOrFoaList
        }
      };
    }
    case REMOVE_VALUE_OR_FOA: {
      const deletedID: number = action.payload;
      const valueOrFoaList = state.workshop.workshopToValueOrFOA.filter(valueOrFoa => valueOrFoa.id !== deletedID);
      return {
        ...state,
        workshop: {
          ...state.workshop,
          workshopToValueOrFOA: valueOrFoaList
        }
      };
    }
    default:
      return state;
  }
};
