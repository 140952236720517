import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { TableProps } from '@qwealth/pdf-support';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { getAge } from 'components/Household/HouseholdUtil';

// Interfaces
type ReportHouseholdItemType = {
  lastName: string;
  firstName: string;
  memberType: string;
  dateOfBirth: string;
}

export type ReportHouseholdProps = {
  data: ReportHouseholdItemType[];
}

const styles = StyleSheet.create({
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    maxWidth: 400,
  },
  Content: {
    display: 'flex',
    alignItems: 'center'
  },
  TableTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 32,
    marginBottom: 4,
    textTransform: 'uppercase',
    width: 600,
    color: COLORS.BLACK_LIGHT,
  },
  TableView: {
    display: 'flex',
    width: 500,
  },
  HeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 44,
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 44, 
  },
  Cell: {
    paddingVertical: 4,
    fontSize: 14,
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: COLORS.GRAY1,
  },
  NameCell: {
    fontSize: 18,
  },
  AgeCell: {
    fontSize: 14,
    color: COLORS.PRIMARY_LIGHT,
  },
  TypeRect: {
    borderBottomWidth: 3,
    borderLeftWidth: 3,
    borderStyle: 'solid',
    borderColor: COLORS.WHITE,
  },
  PrimaryRect: {
    color: COLORS.PRIMARY_LIGHT,
    backgroundColor: COLORS.BACKGROUND.PRIMARY_LIGHT,
  },
  NormalRect: {
    backgroundColor: COLORS.BACKGROUND.GRAY_LIGHT,
  },
  TypeCell: {
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
  },
});

const COLUMNS: TableProps<ReportHouseholdItemType>['cols'] = [
  { title: 'Name', val: ({ firstName, lastName }) => `${firstName} ${lastName}` },
  { title: 'Age', val: ({ dateOfBirth }) => getAge(dateOfBirth) },
  { title: 'Member Type', val: ({ memberType }) => memberType },
];

export const StyledTable = ({cols, data}: {cols: TableProps<ReportHouseholdItemType>['cols'], data: any[]}) => (
  <View style={{width: '100%'}}>
    {data.map((row, rowIndex) => (
      <View style={styles.Row} key={`row-${rowIndex}`}>
        <View style={[styles.Cell, {width: '50%', alignItems: 'flex-start'}]}>
          <Text style={styles.NameCell}>
            {cols[0].val(row)}
          </Text>
        </View>
        <View style={[styles.Cell, {width: '25%'}]}>
          <Text style={{color: COLORS.GRAY2, fontSize: 10, marginBottom: 4}}>AGE</Text>
          <Text style={styles.AgeCell}>
            {cols[1].val(row)}
          </Text>
        </View>
        <View style={[styles.Cell, styles.TypeRect, cols[2].val(row) === 'Primary' ? styles.PrimaryRect: styles.NormalRect,  {width: '25%'}]}>
          <Text style={styles.TypeCell}>
            {cols[2].val(row)}
          </Text>
        </View>
      </View>
    ))}
  </View>
)

export const ReportHouseholdMembers = ({ data = [] }: ReportHouseholdProps) => {
  return (
    <Page size="A4" orientation='landscape' style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          HOUSEHOLD MEMBERS
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Content}>
        <Text style={styles.Title}>
          In order to chart a ‘Journey’ we need to know <br />
          for whom the journey is planned.
        </Text>
        <View style={styles.TableView}>
          <Text style={styles.TableTitle}>
            Household
          </Text>
          <StyledTable cols={COLUMNS} data={data} />
        </View>
      </View>
    </Page>
  );
}