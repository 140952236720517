import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 12,
    paddingHorizontal: 12,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    height: 220
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 60,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 60,
    paddingHorizontal: 20,
    fontSize: 16
  },
  LineLabel: {
    marginTop: 4
  }
});

const list = [
  {
    text: 'PROVIDES A LAYER OF PROTECTION',
    label: 'FROM THE PITFALLS OF LIFE.'
  },
  {
    text: 'ENHANCES YOUR LIFESTYLE BY TANGIBLE BENEFITS',
    label: 'OR GREATER OPTIONS AND CHOICES.'
  },
  {
    text: 'THE ABILITY TO SHARES THESE BENEFITS WITH OTHERS.',
    label: ''
  }
];

export const ReportFunctionsOfAssets = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>FUNCTIONS OF ASSETS</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <Text style={styles.MainText1}>
      As we continue to build your <Text style={styles.HighLight1}>‘Journey’, {'\r\n'}</Text>
      we now focus on what you want your assets to accomplish for you.
    </Text>
    <Text style={styles.SubText1}>
      We believe that money, or any asset, allows us these three opportunities:
    </Text>

    <View style={styles.Content}>
      <View style={styles.TextPanel}>
        {list.map((rowData, index) => (
          <View style={styles.Row} key={index}>
            <View
              style={[
                styles.NumberColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
              ]}
            >
              <Text>{index + 1}</Text>
            </View>
            <View
              style={[
                styles.TextColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
              ]}
            >
              <View>
                <Text>{rowData.text}</Text>
                <Text style={styles.LineLabel}>{rowData.label}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
      <Image style={styles.BannerImage} src="/assets/qw-pdf-img3.jpg" />
    </View>

    <Text style={styles.SubText2}>
      This exercise was constructed to allow you to ask the question:
    </Text>
    <Text style={styles.MainText2}>What do I want my money to do for me?</Text>
  </Page>
);
