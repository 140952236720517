import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Image, View } from '@react-pdf/renderer';
import { Chart } from 'chart.js';

import type { ChartConfiguration } from 'chart.js';

export const useChartJsAsImage = (config: ChartConfiguration): ReactElement => {
  const [base64, setBase64] = useState<string>('');
  const chartInitializedRef = useRef(false);

  useEffect(() => {
    let canvas: HTMLCanvasElement | undefined;
    if (!chartInitializedRef.current && config.data.datasets.length) {
      chartInitializedRef.current = true;
      canvas = document.createElement('canvas');
      canvas.style.display = 'none';
      document.body.append(canvas);

      new Chart(
        canvas,
        Object.assign(config, {
          plugins: [
            ...(config?.plugins ?? []),
            {
              // Saves chart to base64 once it's rendered
              afterRender: (c: any) => {
                setBase64(c.toBase64Image());
              }
            }
          ]
        })
      );
    }

    return () => {
      if (canvas) document.body.removeChild(canvas);
    };
  }, [config]);

  if (!base64) return <View />;
  return <Image src={base64} />;
};
