import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppLayout, styled } from '@qwealth/qcore';
import { useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';

import { BsPeopleFill } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import {
  FaBackward,
  FaRegMoneyBillAlt,
  FaPaperPlane,
  FaChartBar,
  FaKey,
  FaLeaf,
  FaLandmark
} from 'react-icons/fa';
import { Form } from 'react-bootstrap';

import { toggleAdvisorView } from 'data/actions/advisorView';
import { RootState } from '../../data/store';

const Link = styled.a`
  text-decoration: none;
  color: #fff;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: #93345d;
  }

  &:active {
    color: #fff;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const { REACT_APP_QWEALTH_QC } = process.env;

export default function SideNav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const advisorView = useSelector((state: RootState) => state.advisorView);
  const household = useSelector((state: RootState) => state.household.household);
  const legalEntities = useSelector((state: RootState) => state.legalEntities);
  const setAdvisorView = useCallback(
    (isAdvisorView: boolean) => dispatch(toggleAdvisorView(isAdvisorView)),
    [dispatch]
  );

  const pathClickHandler = (pathname: string) => () =>
    navigate({
      search: window.location.search.replace('?', ''),
      pathname
    });

  const matchPaths = useCallback(
    (values: Array<string>) => {
      let match = false;
      values.forEach((value) => {
        const path = matchPath({
          path: value
        }, location.pathname);
        if (path) {
          match = true;
        }
      });
      return match;
    },
    [location]
  );

  const getActiveClass = useCallback((values: Array<string>) => matchPaths(values) ? 'active' : undefined, [matchPaths]);

  useEffect(() => {
    if (
      !advisorView &&
      (matchPaths(['/qroutes']) || matchPaths(['/baselinePlan']) || matchPaths(['/reports']))
    ) {
      setAdvisorView(true);
    }
  }, [advisorView, matchPaths, setAdvisorView]);

  const householdId = new URLSearchParams(window.location.search).get('household');
  const qcHouseholdParams = household?.type === 'Prospect'
    ? `prospect-households/${householdId}`
    : `household?household=${householdId}`;

  return (
    <AppLayout.SideNav title="QLife">
      <AppLayout.SideNavLinks>
        <AppLayout.SideNavLinkItem
          activeClass={getActiveClass(['/household'])}
          title="Household"
          icon={BsPeopleFill}
          onClick={pathClickHandler('/household')}
        />
        <AppLayout.SideNavLinkItem
          activeClass={getActiveClass(['/netWorth'])}
          title="Net Worth"
          icon={FaRegMoneyBillAlt}
          onClick={pathClickHandler('/netWorth')}
        />
        {legalEntities.length > 0 && (
          <AppLayout.SideNavLinkItem
            activeClass={getActiveClass(['/legalEntities'])}
            title="Legal Entities"
            icon={FaLandmark}
            onClick={pathClickHandler('/legalEntities')}
          />
        )}
        <AppLayout.SideNavLinkItem
          activeClass={getActiveClass(['/values'])}
          title="Personal Values"
          icon={FaLeaf}
          onClick={pathClickHandler('/values')}
        />
        <AppLayout.SideNavLinkItem
          activeClass={getActiveClass(['/FOA']) }
          title="Function of  Assets"
          icon={FaPaperPlane}
          onClick={pathClickHandler('/FOA')}
        />

        {advisorView && (
          <>
            <AppLayout.SideNavLinkItem
              activeClass={getActiveClass(['/baselinePlan'])}
              title="Baseline Plan"
              icon={FaChartBar}
              onClick={pathClickHandler('/baselinePlan')}
            />
            <AppLayout.SideNavLinkItem
              activeClass={getActiveClass(['/qroutes'])}
              title="QRoutes"
              icon={FaKey}
              onClick={pathClickHandler('/qroutes')}
            />
            <AppLayout.SideNavLinkItem
              activeClass={getActiveClass(['/reports'])}
              title="Reports"
              icon={HiOutlineDocumentReport}
              onClick={pathClickHandler('/reports')}
            />
            <AppLayout.SideNavLinkItem
              activeClass={getActiveClass(['/qscoreReport'])}
              title="QScore Report"
              icon={HiOutlineDocumentReport}
              onClick={pathClickHandler('/qscoreReport')}
            />
          </>
        )}
      </AppLayout.SideNavLinks>
      <AppLayout.SideNavCollapsedHidden>
        <Form.Check
          type="switch"
          id="advisorView"
          label="Advisor View"
          checked={advisorView}
          onChange={(e) => setAdvisorView(e.target.checked)}
        />
        <Link href={`${REACT_APP_QWEALTH_QC}/${qcHouseholdParams}`}>Back to QCentral &gt;</Link>
      </AppLayout.SideNavCollapsedHidden>

      <AppLayout.SideNavCollapsedVisible>
        <Link href={REACT_APP_QWEALTH_QC}>
          <FaBackward />
        </Link>
      </AppLayout.SideNavCollapsedVisible>
    </AppLayout.SideNav>
  );
}
