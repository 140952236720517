import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import { dismissNotification } from '../../data/actions/notifications';
import './index.scss';

export default function Notifcations() {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useCallback(useDispatch(), []);
  return (
    <>
      {notifications.length > 0 && (
        <div aria-live="polite" aria-atomic="true" className="notifications">
          <div className="toast-wrapper">
            {notifications.map((n) => (
              <Toast
                key={n.notificationUuid}
                onClose={() => dispatch(dismissNotification(n.notificationUuid))}
              >
                <Toast.Header>
                  <img src="holder.js/20x20?text=%20&bg=#FF0000" className="rounded mr-2" alt="" />
                  <strong className="mr-auto">{n.level}</strong>
                  <small>{n.timeStamp}</small>
                </Toast.Header>
                <Toast.Body>{n.text}</Toast.Body>
              </Toast>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
