import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 12,
    paddingHorizontal: 12,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    height: 220
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 60,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 60,
    paddingHorizontal: 20,
    fontSize: 16
  },
  LineLabel: {
    marginTop: 4
  }
});

const list = [
  {
    text: 'KNOW WHAT IS IMPORTANT TO YOU.',
    label: ''
  },
  {
    text: 'UNDERSTAND THE BENEFIT YOU WOULD GAIN FROM',
    label: 'HAVING THAT VALUE REPRESENTED IN YOUR LIFE.'
  },
  {
    text: 'IDENTIFY THE HURDLES IN YOUR LIFE, BLOCKING',
    label: 'YOU FROM THAT VALUE.'
  }
];

// Interfaces
export type ReportPersonalValuesTopTenProps = {
  values: Array<{
    type: string;
  }>;
};

export type ReportPersonalValuesTopFiveProps = {
  values: Array<{
    type: string;
    definitionFreeText?: string;
    successCriteriaFreeText?: string;
    preventCriteriaFreeText?: string;
  }>;
};

export const ReportPersonalValuesV2 = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
    <View style={commonStyles.Header}>
      <Text style={commonStyles.HeaderTitle}>PERSONAL VALUES</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <Text style={styles.MainText1}>
      Now that we know for whom we are creating this{' '}
      <Text style={styles.HighLight1}>‘Journey’, </Text> and {'\r\n'}
      what has been accumulated to date, let’s find out what is important to you.
    </Text>
    <Text style={styles.SubText1}>
      Values are fundamental beliefs that motivate our actions. They propel us forward on our
      ‘Journey’. In clearly articulating your values we attempt to:
    </Text>

    <View style={styles.Content}>
      <View style={styles.TextPanel}>
        {list.map((rowData, index) => (
          <View style={styles.Row} key={index}>
            <View
              style={[
                styles.NumberColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
              ]}
            >
              <Text>{index + 1}</Text>
            </View>
            <View
              style={[
                styles.TextColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
              ]}
            >
              <View>
                <Text>{rowData.text}</Text>
                <Text style={styles.LineLabel}>{rowData.label}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
      <Image style={styles.BannerImage} src="/assets/qw-pdf-img2.jpg" />
    </View>

    <Text style={styles.SubText2}>
      This exercise is designed to allow you to ask the fundamental question:
    </Text>
    <Text style={styles.MainText2}>
      In a world where I can choose to have {'\r\n'}
      my life be about something, <Text style={styles.HighLight1}>what do I choose?</Text>
    </Text>
  </Page>
);
