import { SET_ADVISOR_VIEW } from '../actions/advisorView';

export default function (state = true, action: { type: string; isAdvisorView: boolean; }): boolean {
  switch (action.type) {
    case SET_ADVISOR_VIEW: {
      return action.isAdvisorView;
    }
    default:
      return state;
  }
}
