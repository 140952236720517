import React from 'react';
import { useSelector } from 'react-redux';
import { PDFViewer, Document, Font } from '@react-pdf/renderer';

// PdfReport Chunks
import ReportCover from '../chunks/ReportCover';
import ReportOverview from '../chunks/ReportOverview';
import ReportComm from '../chunks/ReportContent';
import ReportNeeds from '../chunks/ReportNeeds';
import ReportTodos from '../chunks/ReportToDos';
import ReportSuggestedStrategies from '../chunks/ReportSuggestedStrategies';
import ReportBaselineAssumption from '../chunks/ReportBaselineAssumption';
import ReportBaselineAssumption1 from '../chunks/ReportBaselineAssumption1';
import ReportReturnAssumption, {
  ReportReturnAssumptionProps
} from '../chunks/ReportReturnAssumption';
import ReportYourQScore from '../chunks/ReportYourQScore';
import ReportLegacyValues from '../chunks/ReportLegacyValues';
import ReportLegacyValues1 from '../chunks/ReportLegacyValues1';
import ReportInOutFlows from '../chunks/ReportInOutFlows';
import { useAggregateQScoreData } from '../chunks/hooks/useAggregateQScoreData';
import { useAggregateBaselinePlanData } from '../chunks/hooks/useAggregateBaselinePlanData';
import { useAggregateAccountsData } from '../chunks/hooks/useAggregateAccountsData';
import { AggregatedQScoreData } from '../chunks/interfaces/AggregatedQScoreData';
import { AggregatedBaselinePlanData } from '../chunks/interfaces/AggregatedBaselinePlanData';

import './index.scss';

// TYPES
import type { IState } from 'data/store';

export type ReportQScoreProps = {
  data: AggregatedQScoreData;
};

Font.registerHyphenationCallback((word: string) => word.split(''));
Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Light.ttf',
      fontWeight: 300
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Regular.ttf',
      fontWeight: 400
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Medium.ttf',
      fontWeight: 500
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-SemiBold.ttf',
      fontWeight: 600
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Bold.ttf',
      fontWeight: 700
    }
  ]
});
Font.registerHyphenationCallback((word) => [word]);

// Interfaces
type ReportBuildProps = {
  dataQScore?: ReportQScoreProps['data'] | null;
  dataBaselinePlan?: AggregatedBaselinePlanData | null;
  dataAccounts?: ReportReturnAssumptionProps['data'] | null;
};

const ReportPageBuilder = ({
  dataQScore = null,
  dataBaselinePlan = null,
  dataAccounts = null
}: ReportBuildProps) => (
  <Document>
    <ReportCover
      personPrimary={dataQScore?.personPrimary}
      personSecondary={dataQScore?.personSecondary}
    />
    <ReportOverview />
    <ReportComm />
    <ReportNeeds />
    <ReportBaselineAssumption />
    <ReportBaselineAssumption1 data={dataBaselinePlan} accounts={dataAccounts} />
    {dataAccounts && <ReportReturnAssumption data={dataAccounts} />}
    {dataQScore && <ReportYourQScore data={dataQScore} />}
    {dataQScore && <ReportLegacyValues data={dataQScore} />}
    {dataQScore && <ReportLegacyValues1 data={dataQScore} />}
    {dataQScore && <ReportInOutFlows data={dataQScore} />}
    {dataQScore && <ReportTodos data={dataQScore} />}
    {dataQScore && <ReportSuggestedStrategies data={dataQScore} />}
  </Document>
);

const ReportBuilder = () => {
  const dataQScore: ReportQScoreProps['data'] | null = useAggregateQScoreData();
  const dataBaselinePlan: AggregatedBaselinePlanData | null = useAggregateBaselinePlanData();
  const dataAccounts = useAggregateAccountsData();

  return (
    <div className="reports-summary">
      <PDFViewer className="pdf-viewer">
        <ReportPageBuilder
          dataQScore={dataQScore}
          dataBaselinePlan={dataBaselinePlan}
          dataAccounts={dataAccounts}
        />
      </PDFViewer>
    </div>
  );
};

export const ReportSummary = () => {
  const householdInitialized = useSelector<IState, boolean>((state) => state.household.initialized);

  return householdInitialized ? <ReportBuilder /> : null;
};
