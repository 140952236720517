import { LOAD_REF_DATA } from '../actions/refData';

// TODO: get from backend later?

export const refData = {
  values: {
    values: [
      'Discipline',
      'Spirituality',
      'Legacy',
      'Honesty',
      'Family',
      'Adventure',
      'Success',
      'Creativity',
      'Community',
      'Generosity',
      'Autonomy',
      'Friendships',
      'Reputation',
      'Wisdom',
      'Relationships',
      'Happiness',
      'Growth',
      'Kindness',
      'Faith',
      'Health',
      'Balance',
      'Stability',
      'Abundance',
      'Work'
    ],
    valueColors: [
      {
        color: '#3772B8',
        shadow: '8px 15px 50px rgba(55, 114, 184, 0.5), -8px 0px 12px rgba(55, 114, 184, 0.2)'
      },
      {
        color: '#D39B48',
        shadow: '8px 15px 50px rgba(211, 155, 72, 0.5), -8px 0px 8px rgba(211, 155, 72, 0.25)'
      },
      {
        color: '#79B94E',
        shadow:
          '8px 15px 50px rgba(121, 185, 78, 0.5), -8px 0px 8px rgba(121, 185, 78, 0.25), -11px -11px 20px rgba(255, 255, 255, 0.272044)'
      },
      {
        color: '#CC433C',
        shadow:
          '8px 15px 50px rgba(204, 67, 60, 0.5), -8px 0px 8px rgba(204, 67, 60, 0.2), -11px -11px 20px rgba(255, 255, 255, 0.272044)'
      },
      {
        color: '#5EC6C2',
        shadow:
          '8px 15px 50px rgba(94, 198, 194, 0.4), -8px 0px 8px rgba(94, 198, 194, 0.15), -11px -11px 20px rgba(255, 255, 255, 0.272044)'
      }
    ]
  },
  FOA: {
    functionDefinitions: {
      Enhancement: [
        'I have a clear idea of specific enhancements that would bring me joy',
        'I would value more freedom to make personal and financial decisions (i.e. work, personal, growth, etc.)',
        'I don\'t have a specific list but could create one if I knew that expanded options were available'
      ],
      Sharing: [
        'I would want to share now and see the benefits of my gift',
        'I would want to give as part of my estate',
        'I would want to give both now as well as part of my estate'
      ],
      Protection: [
        'An immediate change to either my current assets or current cash flows that would affect decisions both today and in the future',
        'An immediate change to my or my significant other\'s current health that would affect decisions both today and in the future',
        'A natural disaster that would affect decisions both today and in the future',
        'A lack of clarity as to what events could cause financial difficulty'
      ]
    },
    functionGoals: {
      Enhancement: {
        0: [
          'If I had _______ in my life, it would bring me joy (can be more than one)',
          'Know the parameters of what I could spend past what I spend now',
          'Know which of these specific goals I can have and which are beyond my means'
        ],
        1: [
          'Know if I can retire sooner than I had planned',
          'Know if I can take a break from work (ie: school, change career)',
          'Know if I could take a reduction in income but be more fulfilled'
        ],
        2: [
          'Know that maximum I could spend prudently',
          'Know the limits of  specific one time event purchases',
          'Know what would happen if a bad event occurred after the spend'
        ]
      },
      Sharing: {
        0: [
          'I have specific things I would want to do with others and not expect them to contribute',
          'I know specific people in need that I would want to help',
          'I have specific charities that I want to give to'
        ],
        1: [
          'I have specific targets of how my estate is to be settled',
          'I would like my estate to continue to give in perpetuity',
          'I don\'t have a specific list but could create one if I knew that expanded options were available'
        ],
        2: [
          'I have specific targets to give now  and then whatever is left over is fine',
          'I want to create gifting strategies that included giving now but also include funding a perpetual giving strategy',
          'I don\'t have a specific list but could create one if I knew that expanded options were available'
        ]
      },
      Protection: {
        0: [
          'Know that I have specific reserve account to carry me through (X) amount of time',
          'Know that I have tested my financial plan for the possibility of this event',
          'Know what other goals in my financial plan are effected and what I would need to do'
        ],
        1: [
          'Know that I have tested my financial plan for the possibility of this event',
          'Know that my documents and estate are in order prior to this event',
          'Know the plan of possible asset movement to mitigate the effects'
        ],
        2: [
          'Know that I have a specific reserve account to carry me through this event',
          'Know that I have tested my financial plan for the possibility of this event',
          'Know if all my insurances are designed to protect in such an event'
        ],
        3: [
          'Have a list of questions with answers',
          'Have seen various scenarios and how they would have played out',
          'Have a regular update on levels of protections'
        ]
      }
    }
  }
};

export default function (state = refData, action) {
  switch (action.type) {
    case LOAD_REF_DATA:
      return action.data;
    default:
      return state;
  }
}
