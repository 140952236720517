import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import {toCurrency, sumValues} from '@qwealth/qcore';

const styles = StyleSheet.create({
  SectionTitle: {
    fontSize: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: COLORS.BACKGROUND.GRAY_LIGHT,
    marginVertical: 3,
    padding: 6,
    width: '100%',
  },
  Content: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 48,
    color: COLORS.BLACK_LIGHT,
  },
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    maxWidth: 400,
  },
  PrimaryLine: {
    backgroundColor: COLORS.BACKGROUND.PRIMARY_LIGHT,
    color: COLORS.PRIMARY,
  },
  GreenLine: {
    backgroundColor: COLORS.BACKGROUND.GREEN_LIGHT,
    color: COLORS.GREEN,
  },
  OrangeLine: {
    backgroundColor: COLORS.BACKGROUND.ORANGE_LIGHT,
    color: COLORS.ORANGE,
  },
  GrayLine: {

  }
});

type ReportNetWorthItemType = {
  accountTitle: string;
  accountSource: string;
  fullName: string;
  accountType: string;
  accountNumber: string;
  isManaged: number,
  value: number | string;
};

// Interfaces
export type ReportNetWorthProps = {
  data: Array<{
    accountTitle: string,
    total: number,
    children: ReportNetWorthItemType[],
  }>,
  totalAssets?: any,
  totalNetWorth?: any,
  totalLiabilities?: any,
  assetNames: any,
  liabilityNames: any,
  assetAccountsByCategories: any,
  liabilityAccountsByCategories: any,
}

export const NetWorthLineView = ({accountTitle, total, color} : {accountTitle: string, total: number, color?: string}) => (
  <View style={[styles.SectionTitle, color === 'green' ? styles.GreenLine: color === 'orange' ? styles.OrangeLine: color === 'primary' ? styles.PrimaryLine: styles.GrayLine]}>
    <Text>{accountTitle}</Text>
    <Text>{toCurrency(total, false)}</Text>
  </View>
);

export const ReportNetWorthV2 = ({
  data = [],
  totalNetWorth,
  totalAssets,
  totalLiabilities,
  assetNames = [],
  liabilityNames = [],
  assetAccountsByCategories,
  liabilityAccountsByCategories,
}: ReportNetWorthProps) => {

  return (
    <Page size="A4" orientation='landscape' style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          NET WORTH
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Content}>
        <Text style={styles.Title}>
          Next, we need to know what those individuals have <br />
          accumulated to this point in their lives.
        </Text>
        <NetWorthLineView accountTitle='Net Worth' total={totalNetWorth} color='primary' />
        <NetWorthLineView accountTitle='Assets' total={totalAssets} color='green' />
        {/* 
        // @ts-ignore */}
        {assetNames.map((name, idx) => (
          <NetWorthLineView key={name} total={sumValues(assetAccountsByCategories[idx])} accountTitle={name} />
        ))}
        <NetWorthLineView accountTitle='Liabilities' total={totalLiabilities} color='orange' />
        {/* 
        // @ts-ignore */}
        {liabilityNames.map((name, idx) => (
          <NetWorthLineView key={name} total={sumValues(liabilityAccountsByCategories[idx])} accountTitle={name} />
        ))}
      </View>
    </Page>
  );
}
