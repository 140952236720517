import { Client } from '@microsoft/microsoft-graph-client';

import { getAccessAndIdToken } from '@qwealth/qcore';

async function getAuthenticatedClient() {
  const authenticationResult = await getAccessAndIdToken();
  // @ts-ignore
  const { accessToken: newAccessToken } = authenticationResult;
  // Initialize Graph client
  return Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, newAccessToken || null);
    }
  });
}

export async function getUserDetails() {
  const client = await getAuthenticatedClient();
  return await client
    .api('/me')
    .select('id,displayName,givenName,mail,mailboxSettings,surname,userPrincipalName')
    .get();
}

export async function sendMail(mail) {
  const client = await getAuthenticatedClient();

  const { value } = await client.api('/me/sendMail').post(mail);

  return value;
}
