import {
  getAnnualSavings,
  nonRegisteredAccountFilter,
  registeredAccountFilter,
  retirementAccountFilter,
  taxFreeAccountFilter
} from '@qwealth/qcore';

const getRetirementSavings = (
  accounts,
  primary,
  secondary,
  filter
) => {
  const filteredAccounts = accounts.filter(filter);

  const primaryValue = filteredAccounts
    .filter((a) => a.accountOwnersQID === primary.QID)
    .filter(({ accountType }) => accountType === 'Investment')
    .map((acc) => getAnnualSavings(acc, primary, secondary))
    .reduce((a, b) => a + b, 0);
  const secondaryValue = filteredAccounts
    .filter((a) => a.accountOwnersQID === ((secondary && secondary.QID) || ''))
    .filter(({ accountType }) => accountType === 'Investment')
    .map((acc) => getAnnualSavings(acc, secondary, primary))
    .reduce((a, b) => a + b, 0);

  return {
    readOnly: true,
    primaryValue,
    secondaryValue
  };
};

export const getRegisteredRetirementSavings = (
  accounts,
  primary,
  secondary
) => {
  return getRetirementSavings(
    accounts,
    primary,
    secondary,
    registeredAccountFilter
  );
};

export const getNonRegisteredRetirementSavings = (
  accounts,
  primary,
  secondary
) => {
  return getRetirementSavings(
    accounts,
    primary,
    secondary,
    nonRegisteredAccountFilter
  );
};

export const getTaxFreeRetirementSavings = (
  accounts,
  primary,
  secondary
) => {
  return getRetirementSavings(
    accounts,
    primary,
    secondary,
    taxFreeAccountFilter
  );
};

export const getTotalRetirementSavings = (
  accounts,
  primary,
  secondary
) => {
  return getRetirementSavings(
    accounts,
    primary,
    secondary,
    retirementAccountFilter
  );
};
