import { Path } from 'react-router';
import { getHouseholdLabel, RecordType, ResultMappers } from '@qwealth/qsearch';
import { AggregateHousehold } from '@qwealth/qdata';

const householdAndProspectTypes: Array<RecordType> = ['Household', 'ProspectHousehold'];
export const recordTypeOptions = householdAndProspectTypes.map(t => ({ label: t, value: t }));

const householdMapper = (household: AggregateHousehold) => ({
  label: getHouseholdLabel(household),
  value: { search: `household=${household.QID}`, pathname: 'household', hash: '' }
});

export const qLifeSearchMapper: ResultMappers<Path> = {
  householdMapper: householdMapper,
  prospectHouseholdMapper: householdMapper
}
