import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginBottom: 8
  },
  Bold: {
    fontWeight: 'bold'
  },
  Contents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: 20
  },
  Menus: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 26,
    fontSize: 14,
    letterSpacing: 2
  },
  BannerImage: {
    width: '40%',
    marginLeft: 32
  }
});

export const ReportContent = ({
  primaryName,
  secondaryName
}: {
  primaryName?: string;
  secondaryName?: string;
}) => {
  const contents = [
    { page: 1, title: 'COVER' },
    { page: 2, title: 'INTRO' },
    { page: 3, title: 'TABLE OF CONTENTS' },
    { page: 4, title: 'HOUSEHOLD MEMBERS' },
    { page: 5, title: 'NET WORTH' },
    { page: 6, title: 'NET WORTH – DETAIL' },
    { page: 7, title: 'VALUES' },
    { page: 8, title: `VALUES – ${primaryName}`, hidden: !primaryName },
    { page: 9, title: `VALUES – ${secondaryName}`, hidden: !secondaryName },
    { page: 10, title: 'FUNCTIONS OF ASSETS' },
    { page: 11, title: 'QBIAS' },
    { page: 12, title: `QBIAS – ${primaryName}`, hidden: !primaryName },
    { page: 13, title: `QBIAS – ${secondaryName}`, hidden: !secondaryName },
    { page: 14, title: 'BASELINE JOURNEY ASSUMPTIONS' },
    { page: 15, title: 'QROUTES' }
  ];

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>TABLE OF CONTENTS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Contents}>
        <View style={styles.Menus}>
          <Text style={styles.Title}>
            Step One: <Text style={styles.Bold}>Journey Based Planning</Text>
          </Text>
          {contents
            .filter((line) => !line.hidden)
            .map(({ page, title }, index) => (
              <View style={styles.Row} key={index}>
                <Text style={{ textAlign: 'right', width: 32 }}>{index + 1}</Text>
                <Text style={{ color: COLORS.GRAY1, paddingHorizontal: 8 }}>/</Text>
                <Text>{title}</Text>
              </View>
            ))}
        </View>
        <Image src="/assets/qw-pdf-img4.jpg" style={styles.BannerImage} />
      </View>
    </Page>
  );
};
