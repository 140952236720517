import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Outlet } from 'react-router-dom';
import { AppLayout, Box } from '@qwealth/qcore';
import { QSearchComponent } from '@qwealth/qsearch';
import { qLifeSearchMapper } from 'utils/searchHelper';
import { loadHousehold } from 'data/actions/household';
import SideNav from './SideNav';
import Notifications from './Notifications';

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <AppLayout>
      <SideNav />
      <AppLayout.Main>
        <Box mb="largest" width={600}>
          <QSearchComponent
            searchMapper={qLifeSearchMapper}
            onChange={(opt) => {
              navigate(opt.value);
              dispatch(loadHousehold());
            }}
            searchFilters={{
              recordTypes: ['Household', 'ProspectHousehold'],
              householdStatuses: ['Active']
            }}
          />
        </Box>
        <Outlet />
        <Notifications />
      </AppLayout.Main>
    </AppLayout>
  )
}

export default Layout;
