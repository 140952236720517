import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toCurrency, getAnnualSavings, getLegacyBalance, useDispatchOnMount } from '@qwealth/qcore';
import {
  Account,
  initializeHouseholdAccounts,
  loadWorkshop
} from '@qwealth/qdata';

import { selectHouseholdId } from 'data/selectors/householdSelectors';
import { getEducationGoalsForReport } from 'data/outcome/educationHelper';
import {
  getRetirementAge,
  getGuranteedRetirementIncome,
  getRetirementSpending
} from 'services/retirementPlanHelper';
import { getRetirementFlows } from 'services/retirementFlowHelper';

// Types
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { IState, RootState } from 'data/store';
import type { AggregatedBaselinePlanData } from '../interfaces/AggregatedBaselinePlanData';

export const useAggregateBaselinePlanData = (): AggregatedBaselinePlanData | null => {
  const [ready, setReady] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<IState, any, AnyAction>>();
  const household = useSelector((state: IState) => state.household);

  // COMMON
  // *******************

  const primary = household.members.find((member) => member.memberType === 'Primary Client');
  const secondary = household.members.find((member) => member.memberType === 'Secondary Client');

  const householdId = useSelector(selectHouseholdId);
  const accounts = useSelector<IState, Account[]>((state) => state.accounts);

  const { initializedWorkshop, workshop } = useSelector((state: RootState) => state.workshop);
  const outcomes = workshop.outcomes;

  const fieldNames = [primary?.firstName || 'N/A'];
  secondary && fieldNames.push(secondary.firstName);

  const retirementAgeCard = getRetirementAge(outcomes?.['Retirement: Age'], primary, secondary);
  const educationGoals = getEducationGoalsForReport(workshop.outcomes?.['Education Goal'] ?? [], household.members);
  const legacyOutcome = outcomes['Legacy: End Value']?.[0];

  useDispatchOnMount(loadWorkshop, householdId, initializedWorkshop ? undefined : true);

  // RETIREMENT SPENDING
  // *******************

  const retirementIncomeCard = getGuranteedRetirementIncome(
    outcomes?.['Guaranteed Retirement Income'] ?? [],
    primary,
    secondary
  );

  const retirementSpendingCard = getRetirementSpending(outcomes?.['Retirement: Spending'] ?? []);
  const annualWithdrawlFromRetirementSavings =
    (retirementSpendingCard.primaryValue ?? 0) -
    (retirementIncomeCard.primaryValue ?? 0) -
    (retirementIncomeCard.secondaryValue || 0);
  
  // Retirement Inflows & Outflows
  // ******************

  const retirementAgeOutcomes = outcomes?.['Retirement: Age'] ?? [];
  const retirementOutflowsCard = getRetirementFlows(
    outcomes,
    primary,
    secondary,
    retirementAgeOutcomes,
    'Post-retirement: Outflow'
  );
  const primaryRetirementOutflows = retirementOutflowsCard.primaryValue ?? 0;
  const secondaryRetirementOutflows = retirementOutflowsCard.secondaryValue ?? 0;

  const retirementInflowsCard = getRetirementFlows(
    outcomes,
    primary,
    secondary,
    retirementAgeOutcomes,
    'Post-retirement: Inflow'
  );
  const primaryRetirementInflows = retirementInflowsCard.primaryValue ?? 0;
  const secondaryRetirementInflows = retirementInflowsCard.secondaryValue ?? 0;

  // RETIREMENT SAVINGS
  // ******************

  const investmentAccounts = accounts.filter((a) => a.accountType === 'Investment');
  const jointAccounts = investmentAccounts.filter(({ ownershipType }) => ownershipType === 'Joint');
  const primaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID, ownershipType }) =>
      accountOwnersQID === primary?.QID && ownershipType === 'Sole'
  );
  const secondaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID }) => accountOwnersQID === secondary?.QID
  );

  useEffect(() => {
    if (!household.initialized) {
      return;
    }
    dispatch(initializeHouseholdAccounts(householdId, false)).then(() => setReady(true));
  }, [household.initialized]);

  if (!ready) return null;

  return {
    'Retirement Age': [
      {
        title: '',
        names: fieldNames,
        value: [retirementAgeCard.primaryValue ?? '', retirementAgeCard.secondaryValue ?? '']
      }
    ],
    'Retirement Spending': [
      {
        title: 'Targeted Annual Retirement Spend',
        names: fieldNames,
        value: [toCurrency(retirementSpendingCard.primaryValue, true)]
      },
      {
        title: 'Guaranteed Retirement Income',
        names: fieldNames,
        value: [
          toCurrency(retirementIncomeCard.primaryValue, true),
          toCurrency(retirementIncomeCard.secondaryValue, true)
        ]
      },
      {
        title: 'Annual Withdrawal From Retirement Savings',
        names: fieldNames,
        value: [toCurrency(annualWithdrawlFromRetirementSavings, true)]
      }
    ],
    'Retirement Savings': [
      {
        title: 'Retirement Savings',
        names: fieldNames,
        value: [
          toCurrency(
            primaryAccounts
              .map((acc) => getAnnualSavings(acc, primary, secondary))
              .reduce((a, b) => a + b, 0),
            true
          ),
          toCurrency(
            secondaryAccounts
              .map((acc) => getAnnualSavings(acc, secondary, primary))
              .reduce((a, b) => a + b, 0),
            true
          ),
          toCurrency(
            jointAccounts
              .map((acc) => getAnnualSavings(acc, primary, secondary))
              .reduce((a, b) => a + b, 0),
            true
          )
        ]
      }
    ],
    'Retirement Inflows': [
      {
        title: 'Retirement Inflows Total',
        names: fieldNames,
        value: [toCurrency(primaryRetirementInflows + secondaryRetirementInflows, true)]
      }
    ],
    'Retirement Outflows': [
      {
        title: 'Retirement Outflows Total',
        names: fieldNames,
        value: [toCurrency(primaryRetirementOutflows + secondaryRetirementOutflows, true)]
      }
    ],
    'Education Goals': [
      {
        title: 'Education Goals',
        names: educationGoals.map(({ title }) => title),
        value: educationGoals.map(({ value }) => toCurrency(value.primaryValue, true))
      }
    ],
    'Legacy Value': [
      {
        title: 'Legacy Ending Assets',
        names: [],
        value: [toCurrency(getLegacyBalance(legacyOutcome), true)]
      }
    ]
  };
};
