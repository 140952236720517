export default {
  Sharing: {
    definition: 'How were you thinking of sharing your estate?',
    goal: 'How can your QWealth Advisor help?'
  },
  Protection: {
    definition: 'What is the first thing you would want to be protected from?',
    definition2: 'What is the next thing you would want to be protected from?',
    goal: 'How can your QWealth Advisor help?'
  },
  Enhancement: {
    definition: 'What is the first enhancement you would want to see in your life?',
    definition2: 'What is the next enhancement you would want to see in your life?',
    goal: 'How can your QWealth Advisor help?'
  }
};
