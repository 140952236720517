import axios from 'axios';
import * as Sentry from '@sentry/react';
import { QWealthAuthorizationInterceptor } from '@qwealth/qdata';
import { putNotification } from '../data/actions/notifications';
import { ValidationError } from '../data/helpers/validationError';

export const { REACT_APP_QWEALTH_API, REACT_APP_QWEALTH_QC_API } = process.env;

export default async function configureAxios() {
  // interceptor to set the authorization only on QW API requests
  axios.interceptors.request.use(QWealthAuthorizationInterceptor);

  return Promise.resolve();
}

export const getHouseholdId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('household');
};

export const responseMapper = (response) => {
  const { data } = response.data;
  return data;
};

export const errorHandler = (dispatch, errorText) => (error) => {
  Sentry.captureException(error);

  console.error(error);

  dispatch(putNotification(errorText || 'API Error. Please contact support', 'Error'));

  return Promise.reject(error);
};

export const notifyError = (error) => (dispatch) => {
  const message = error instanceof ValidationError && error.message;

  errorHandler(dispatch, message)(error);
};
