import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const ReportLegacyValues = ({ data }: { data?: AggregatedQScoreData }) => {
  const chartLegacyValueImg = useChartJsAsImage(
    crateChartJsConfig('bar', data!.chartLegacyValue.labels, data!.chartLegacyValue.datasets)
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>YEAR END AND LEGACY VALUES</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30,
          paddingTop: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report gives you a snapshot of what the year end value of your estate{' '}
          <Text style={styles.BoldFont}>MIGHT</Text> be. It is{' '}
          <Text style={styles.BoldFont}>highly unlikely</Text> that your year end values{' '}
          <Text style={styles.BoldFont}>will mirror this report</Text> however, this does provide
          you with a general idea of the flows of your assets. It is imperative that we{' '}
          <Text style={styles.BoldFont}>update this plan</Text> on a regular basis to measure how
          actual returns affect the year end legacy results.
        </Text>
        {chartLegacyValueImg}
        <Text style={{ marginTop: 10, fontSize: 10, color: '#aaaaaa', fontWeight: 'bold' }}>
          This includes proceeds from any Life Insurance Policies that would distribute at your
          death.
        </Text>
      </View>
    </Page>
  );
};

export default ReportLegacyValues;
