import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

// Common Components
import { TableProps } from '@qwealth/pdf-support';
// Types
import type { ISimulationType } from '@qwealth/qdata';

const styles = StyleSheet.create({
  Content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 24,
    color: COLORS.BLACK_LIGHT,
  },
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    width: '100%',
  },
  HighLight: {
    // color: COLORS.PRIMARY,
  },
  TitleLine: {
    fontSize: 14,
    marginVertical: 8,
  },
  HeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 24,
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 2,
    borderStyle: 'solid',
    borderColor: COLORS.GRAY1,
    minHeight: 24, 
  },
  Cell: {
    paddingVertical: 4,
    fontSize: 10,
  }
});
// Interfaces
type ReportQRoutesItemType = {
  amount?: number;
  endAge?: string;
  startAge?: string;
  frequency?: string;
  attendeeQID?: string;
  simulationType: ISimulationType;
  retirementAgeValue?: number;
};

export type ReportQRoutesProps = {
  data: ReportQRoutesItemType[];
};

const COLUMNS: TableProps<ReportQRoutesItemType>['cols'] = [
  { title: 'Owner', val: ({ attendeeQID }) => attendeeQID },
  { title: 'Type', val: ({ simulationType }) => simulationType },
  { title: 'Value / Age', val: ({ amount, retirementAgeValue }) => amount || retirementAgeValue },
  { title: 'Frequency', val: ({ frequency }) => frequency },
  { title: 'Start Date', val: ({ startAge }) => startAge },
  { title: 'End Date', val: ({ endAge }) => endAge },
];

export const StyledTable = ({cols, data}: {cols: TableProps<ReportQRoutesItemType>['cols'], data: any[]}) => (
  <View style={{width: '100%'}}>
    <View style={styles.HeaderRow}>
    {cols.map((col, index) => (
      <View key={`header-${index}`} style={[styles.HeaderRow, {width: `${100 / cols.length}%`}]}>
        <Text style={[styles.Cell, {textAlign: index === 0 ? 'left' : index === cols.length - 1 ? 'right': 'center'}]}>
          {col.title}
        </Text>
      </View>
    ))}
    </View>
    {data.map((row, rowIndex) => (
      <View style={styles.Row} key={`row-${rowIndex}`}>
        {cols.map((col, colIndex) => (
          <View key={`header-${colIndex}`} style={[styles.HeaderRow, {width: `${100 / cols.length}%`}]}>
            <Text style={[styles.Cell, {textAlign: colIndex === 0 ? 'left' : colIndex === cols.length - 1 ? 'right': 'center'}]}>
              {col.val(row) || '-'}
            </Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

export const ReportQRoutesV2 = ({ data = [] }: ReportQRoutesProps) => {
  return (
    <Page size="A4" orientation='landscape' style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          QROUTES
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Content}>
        <StyledTable
          cols={COLUMNS}
          data={data} />
      </View>
    </Page>
  );
}
