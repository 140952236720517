import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center',
  },
  SubText1: {
    fontSize: 12,
    paddingHorizontal: 12,
    textAlign: 'center',
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center',
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center',
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT,
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginHorizontal: 30,
    width: '100%',
    marginVertical: 20,
  },
  BannerImage: {
    height: 220,
  },
  TextPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
    padding: 12,
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    // flex: 1,
    flexGrow: 1,
    minHeight: 60,
    width: '50%',
    // padding: 12,
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    maxWidth: 40,
    // flexGrow: 1,
    // height: 60,
    color: COLORS.WHITE,
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    // height: 60,
    paddingHorizontal: 12,
    paddingVertical: 6,
    fontSize: 14,
  },
  LineLabel: {
    fontSize: 14,
  },
  TextLabel: {
    fontSize: 14,
    marginBottom: 4,
    color: COLORS.PRIMARY_LIGHT,
  }
});

const list = [
  {
    index: 1,
    text: 'CONFIRMATION BIAS',
    label: 'Seeking to confirm a belief system you already hold.',
    isLight: true,
  },
  {
    index: 3,
    text: 'LOSS AVERSION AND CERTAINTY BIAS',
    label: 'Emotionally seeking certain outcome or avoiding losses at the expense of better options for gains.',
  },
  {
    index: 2,
    text: 'SMALL NUMBER BIAS',
    label: 'Drawing conclusions from small data sets that are anecdotal and not representative.',
  },
  {
    index: 4,
    text: 'FRAMING AND ANCHORING BIAS',
    label: 'Allowing the manner in which information is presented or has been presented to dominate your decisions.',
    isLight: true,
  },
];

// Interfaces
export type ReportPersonalValuesTopTenProps = {
  values: Array<{
    type: string;
  }>;
};

export type ReportPersonalValuesTopFiveProps = {
  values: Array<{
    type: string;
    definitionFreeText?: string;
    successCriteriaFreeText?: string;
    preventCriteriaFreeText?: string;
  }>;
};

export const ReportQBias = () => (
  <Page size="A4" orientation='landscape' style={commonStyles.Root}>
    <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
    <View style={commonStyles.Header}>
      <Text style={commonStyles.HeaderTitle}>
        QBIAS
      </Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <Text style={styles.MainText1}>
      We now have a clear, codified statement of the Values we want represented in our lives {'\r\n'}
      and what we expect of our assets to take us there. However, it is imperative that {'\r\n'}
      we understand potential speedbumps on our <Text style={styles.HighLight1}>‘Journey’. </Text>{'\r\n'}
    </Text>
    <Text style={styles.SubText1}>
      <Text style={styles.HighLight1}>Journey Based Planning</Text> is the first financial planning platform to substantially incorporate Behavioral Economics into the planning process. {'\r\n'}
      This body of research has proven that our demonstrated actions often contradict what we have stated as our values or desired outcomes. {'\r\n'}
      Your responses on this survey have provided insight into your instinctive biases in the areas of:
    </Text>

    <View style={styles.Content}>
      <View style={styles.TextPanel}>
        {list.map((rowData) => (
          <View style={styles.Row} key={rowData.index}>
            <View style={[styles.NumberColumn, {backgroundColor: rowData.isLight ? COLORS.PRIMARY_LIGHT: COLORS.PRIMARY}]}>
              <Text>{rowData.index}</Text>
            </View>
            <View style={[styles.TextColumn, {backgroundColor: rowData.isLight ? COLORS.GRAY1: COLORS.GRAY2}]}>
              <View>
                <Text style={styles.TextLabel}>{rowData.text}</Text>
                <Text style={styles.LineLabel}>{rowData.label}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>

    <Text style={styles.SubText2}>
      This exercise challenges you to ask the question:
    </Text>
    <Text style={[styles.MainText2, styles.HighLight1]}>
      Are my actions taking me {'\r\n'}
      towards my values or away from them?
    </Text>
  </Page>
);
