// TODO: load the locale via household location!

export const dateFormatter = new Intl.DateTimeFormat('en-US');
export const percentFormatter = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 });

export const parsePercent = (percentString) => parseFloat(percentString) / 100.0;

export const getMultiplier = (f) => {
  if (f === 'Monthly') return 12;
  if (f === 'Bi-Monthly') return 24;
  if (f === 'Every 2 years') return 0.5;
  if (f === 'Every 4 years') return 0.25;
  if (f === 'Every 5 years') return 0.20;
  if (f === 'Every 10 years') return 0.1;

  return 1;
};

export const getYearMultiple = (f) => {
  if (f === 'Every 2 years') return 2;
  if (f === 'Every 4 years') return 4;
  if (f === 'Every 5 years') return 5;
  if (f === 'Every 10 years') return 10;

  return 1;
};
