import { getUserDetails } from 'services/graphService';
import { fetchUserByUsername, IUser } from '@qwealth/qdata';

export const SET_USER = 'SET_MS_USER';

export const EST = 'Eastern Standard Time';

export const FALLBACK_TIMEZONE = 'Eastern Standard Time';

export const setUser = (user: any) => ({ type: SET_USER, user });

export const loadUser = () => async (dispatch: any) => {
  const userDetails = await getUserDetails();
  const { id, givenName, displayName, mailboxSettings, mail, surname, userPrincipalName } =
    userDetails;

  const qwUsers: IUser[] = await fetchUserByUsername(mail || userPrincipalName, false);

  // Use Fallback timeZone if not found
  const timeZone = mailboxSettings?.timeZone ? mailboxSettings.timeZone : FALLBACK_TIMEZONE;
  const timeFormat = mailboxSettings?.timeFormat ? mailboxSettings.timeFormat : EST;

  dispatch(
    setUser({
      ...qwUsers[0],
      displayName,
      email: mail || userPrincipalName,
      givenName,
      id,
      surname,
      timeFormat,
      timeZone
    })
  );
};
