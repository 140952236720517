import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 12,
    paddingHorizontal: 12,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    height: 220
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 60,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 60,
    paddingHorizontal: 20,
    fontSize: 16
  },
  LineLabel: {
    color: COLORS.PRIMARY_LIGHT,
    letterSpacing: 2,
    fontSize: 14
  }
});

const list = [
  {
    text: 'YOUR INTRINSIC MOTIVATIONS',
    label: 'VALUES EXERCISE'
  },
  {
    text: 'YOUR EXPECTATIONS OF THE ASSETS YOU HAVE',
    label: 'FUNCTIONS OF ASSETS EXERCISE'
  },
  {
    text: 'YOUR INSTINCTIVE BIASES',
    label: 'QBIAS EXERCISE'
  }
];

export const ReportOverview = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>JOURNEY BASED PLANNING</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <Text style={styles.MainText1}>
      Our belief is that life is a unique journey. A journey comprised of twists and turns,
      inevitably sending you on unexpected routes. We understand that happiness is achieved, not by
      reaching the end of the journey, rather, by savoring the steps along the way.
    </Text>
    <Text style={styles.SubText1}>
      While the numbers and data are imperative to create a financial plan, we know that to create a{' '}
      <Text style={styles.HighLight1}>Journey Based Plan</Text>, additional knowledge of who you are
      and what motivates you, creates a more intimate and engaged understanding of you as a person.
      To that end, we created these exercises to gain insight into:
    </Text>

    <View style={styles.Content}>
      <View style={styles.TextPanel}>
        {list.map((rowData, index) => (
          <View style={styles.Row} key={index}>
            <View
              style={[
                styles.NumberColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
              ]}
            >
              <Text>{index + 1}</Text>
            </View>
            <View
              style={[
                styles.TextColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
              ]}
            >
              <View>
                <Text>{rowData.text}</Text>
                <Text style={styles.LineLabel}>{rowData.label}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
      <Image style={styles.BannerImage} src="/assets/qw-pdf-img5.jpg" />
    </View>

    <Text style={styles.SubText2}>
      This document is <Text style={styles.HighLight1}>Step One</Text> towards creating your{' '}
      <Text style={styles.HighLight1}>Journey Based Plan</Text>. By the end of this document, you
      will have a fundamental understanding of:
    </Text>
    <Text style={styles.MainText2}>Who you are, and what you want.</Text>
  </Page>
);
