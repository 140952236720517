import { PUT_HOUSEHOLD_QBIAS } from '../actions/householdQbias';

export default function (state = {}, action) {
  switch (action.type) {
    case PUT_HOUSEHOLD_QBIAS: {
      const { qbias } = action;
      if (qbias) {
        return qbias;
      }
      return state;
    }
    default:
      return state;
  }
}
