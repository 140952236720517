import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 10,
    paddingHorizontal: 12,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 10,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    height: 180
  },
  TextPanel: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 50,
    color: COLORS.WHITE,
    fontWeight: 'bold'
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 50,
    paddingHorizontal: 20,
    fontSize: 12,
    fontWeight: 'bold'
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const list1 = [
  {
    text: 'Should I retire now, or do I need to work 5 years longer?'
  },
  {
    text: 'Can I buy a second home?\nIs it possible if my son took out some student loans?'
  },
  {
    text: 'Can I afford to take two years off work and go back to school?\nIs is possible if I cut back on travel during that time?'
  }
];

const ReportNeeds = () => (
  <Page size="A4" orientation="landscape" style={[commonStyles.Root, { paddingBottom: 100 }]}>
    <View style={[commonStyles.Header, { marginBottom: 30 }]} fixed>
      <Text style={commonStyles.HeaderTitle}>WHAT IS A QSCORE?</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View
      style={{
        paddingHorizontal: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text style={styles.MainText1}>
        Your QScore is a <Text style={styles.BoldFont}>‘numeric snapshot in time’</Text> which tells
        you what percentage of your Desired Outcomes in your{' '}
        <Text style={styles.BoldFont}>‘Journey Based Plan’</Text> you are likely to achieve. This
        score is designed to help with your <Text style={styles.BoldFont}>‘decision making’</Text>{' '}
        when the outcomes of your decisions are uncertain.
      </Text>
      <Text style={[styles.SubText1, { marginTop: 20 }]}>
        Think of your QScore as providing a scorecard when weighing one decision against another.
        For example:
      </Text>

      <View style={styles.Content}>
        <View style={styles.TextPanel}>
          {list1.map((rowData, index) => (
            <View style={styles.Row} key={index}>
              <View
                style={[
                  styles.NumberColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
                ]}
              >
                <Text>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.TextColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
                ]}
              >
                <Text>{rowData.text}</Text>
              </View>
            </View>
          ))}
        </View>
        <Image style={styles.BannerImage} src="/assets/qw-qscore-pdf-img3.jpeg" />
      </View>

      <Text style={[styles.MainText1, { paddingHorizontal: 20 }]}>
        By converting the potential outcomes of your decisions into QScores, you can choose the
        path, <Text style={styles.BoldFont}>‘QRoute’</Text>, that will align the outcomes you most
        desire, with the highest probability of success.
      </Text>
    </View>
  </Page>
);

export default ReportNeeds;
