import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { NetWorthLineView } from '../ReportNetWorthV2';

// Common Components
import { TableProps } from '@qwealth/pdf-support';
import {toCurrency, sumValues} from '@qwealth/qcore';
import { ReportNetWorthProps } from '../ReportNetWorthV2';


const styles = StyleSheet.create({
  SectionTitle: {
    fontSize: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: COLORS.BACKGROUND.GRAY_LIGHT,
    marginBottom: 4,
    padding: 6,
    width: '100%',
  },
  Content: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 48,
    color: COLORS.BLACK_LIGHT,
  },
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    maxWidth: 400,
  },
  Table: {
    width: '100%',
    fontSize: 12,
  },

  HeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 24,
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 2,
    borderStyle: 'solid',
    borderColor: COLORS.GRAY1,
    minHeight: 24, 
  },
  Cell: {
    paddingVertical: 4,
    fontSize: 10,
  }
});

type ReportNetWorthItemType = {
  [key: string]: any;
  accountTitle: string;
  accountSource: string;
  fullName: string;
  accountType: string;
  accountNumber: string;
  isManaged: number,
  value: number | string;
};

const SECTION_COLUMNS: TableProps<ReportNetWorthItemType>['cols'] = [
  { title: 'Account Title', val: ({ accountTitle }) => accountTitle },
  { title: 'Registered', val: ({ accountOwner }) => accountOwner },
  { title: 'Type/SubType', val: ({ accountType }) => accountType },
  // { title: 'Source', val: ({ accountSource }) => accountSource },
  { title: 'Account Number', val: ({ accountNumber }) => accountNumber },
  // { title: 'Is Managed Account?', val: ({ isManaged }) => isManaged ? 'yes' : 'no' },
  // @ts-ignore
  { title: 'Account Value', val: ({ value }) => `${toCurrency(value, true)}` },
];

const StyledTable = ({cols, data}: {cols: TableProps<ReportNetWorthItemType>['cols'], data: ReportNetWorthItemType[]}) => (
  <View style={{width: '100%'}}>
    <View style={styles.HeaderRow}>
    {cols.map((col, index) => (
      <View key={`header-${index}`} style={[styles.HeaderRow, {width: `${100 / cols.length}%`}]}>
        <Text style={[styles.Cell, {textAlign: index === 0 ? 'left' : index === cols.length - 1 ? 'right': 'center'}]}>
          {col.title}
        </Text>
      </View>
    ))}
    </View>
    {data.map((row, rowIndex) => (
      <View style={styles.Row} key={`row-${rowIndex}`}>
        {cols.map((col, colIndex) => (
          <View key={`header-${colIndex}`} style={[styles.HeaderRow, {width: `${100 / cols.length}%`}]}>
            <Text style={[styles.Cell, {textAlign: colIndex === 0 ? 'left' : colIndex === cols.length - 1 ? 'right': 'center'}]}>
              {col.val(row)}
            </Text>
          </View>
        ))}
      </View>
    ))}
  </View>
)

export const ReportNetWorthDetails = ({
  data = [],
  totalNetWorth,
  totalAssets,
  totalLiabilities,
  assetNames = [],
  liabilityNames = [],
  assetAccountsByCategories,
  liabilityAccountsByCategories,
}: ReportNetWorthProps) => {

  return (
    <Page size="A4" orientation='landscape' style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          NET WORTH - DETAIL
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Content}>
        <Text style={styles.Title}>
          Let’s drill down into this a little deeper.
        </Text>
        <NetWorthLineView accountTitle='Assets' total={totalAssets} color='green' />
        {/* 
        // @ts-ignore */}
        {assetNames.map((name, idx) => (
          <View style={{width: '100%'}} wrap={false} key={name}>
            <NetWorthLineView total={sumValues(assetAccountsByCategories[idx])} accountTitle={name} />
            <StyledTable
              data={assetAccountsByCategories[idx]}
              cols={SECTION_COLUMNS}
            />
          </View>
        ))}
        <View style={{width: '100%'}} wrap={false}>
          <NetWorthLineView accountTitle='Liabilities' total={totalLiabilities} color='orange' />
        </View>
        {/* 
        // @ts-ignore */}
        {liabilityNames.map((name, idx) => (
          <View style={{width: '100%'}} wrap={false} key={name}>
            <NetWorthLineView total={sumValues(liabilityAccountsByCategories[idx])} accountTitle={name} />
            <StyledTable
              data={liabilityAccountsByCategories[idx]}
              cols={SECTION_COLUMNS}
            />
          </View>
        ))}
      </View>
    </Page>
  );
}
