import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 12,
    paddingHorizontal: 12,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginVertical: 30
  },
  BannerImage: {
    height: 200,
    width: 300
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center',
    width: '70%'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 35,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 35,
    paddingHorizontal: 20,
    fontSize: 12
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const list = [
  {
    title: 'Values Exercise',
    text: 'Who Are You and What Do You Want?'
  },
  {
    title: 'Functions of Assets',
    text: 'What Do You Want Your Money To Do For You?'
  },
  {
    title: 'QBias Survey',
    text: 'What Are Your Inherent Biases That Affect Your Decision Making?'
  },
  {
    title: 'QData Link',
    text: 'Additional Numerical Data'
  }
];

const ReportBaselineAssumption = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>BASELINE PLAN ASSUMPTIONS</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={styles.Content}>
      <Image style={styles.BannerImage} src="/assets/qw-qscore-pdf-img5.jpeg" />
    </View>

    <Text style={styles.MainText1}>
      Let’s begin with your <Text style={styles.BoldFont}>starting point assumptions.</Text>
    </Text>
    <Text style={styles.MainText1}>
      These assumptions were derived from the <Text style={styles.BoldFont}>QLife Exercises:</Text>
    </Text>

    <View style={styles.Content}>
      <View style={styles.TextPanel}>
        {list.map((rowData, index) => (
          <View style={styles.Row} key={index}>
            <View
              style={[
                styles.NumberColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
              ]}
            >
              <Text style={styles.BoldFont}>{index + 1}</Text>
            </View>
            <View
              style={[
                styles.TextColumn,
                { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
              ]}
            >
              <Text>
                <Text style={styles.BoldFont}>{rowData.title}</Text> - {rowData.text}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  </Page>
);

export default ReportBaselineAssumption;
