import { IPerson, EducationOutcome } from '@qwealth/qdata';
import { CardMapItem } from 'data/types/workshop';

export const getEducationGoalsForPlan = (
  members: IPerson[],
  educationGoals?: EducationOutcome[]
): CardMapItem[] => {
  const outcomesWithName = educationGoals?.map((outcome) => ({
    ...outcome,
    studentName: members.find((member) => member.QID === outcome.studentQID)?.firstName
  }));
  const educationCards: CardMapItem[] = [
    {
      title: 'Education Goals',
      value: {
        type: 'Education Goal',
        primaryGoal: outcomesWithName
      }
    }
  ];
  return educationCards;
};

export const getEducationGoalsForReport = (
  educationGoals: EducationOutcome[],
  members: IPerson[]
): CardMapItem[] => {
  let educationCards: CardMapItem[] = [
    {
      title: 'No goals',
      value: {
        primaryValue: undefined
      }
    }
  ];
  if (educationGoals.length) {
    educationCards = educationGoals.map((goal) => ({
      title:
        members.find((member) => member.QID === goal.studentQID)?.firstName ??
        'Student not found in household',
      value: {
        primaryValue: goal.educationCostPerYear * goal.educationDuration
      }
    }));
  }
  return educationCards;
};
