import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

// Constants
import { EMPTY_VALUE } from '../../constant';


import { Heading } from '@qwealth/pdf-support';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  Root: {
    padding: 12,
  },
  Member: {
    padding: 10,
    paddingBottom: 0,
    marginBottom: 10,
  },
  SectionTitle: {
    fontSize: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ValuesRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  ValueCircle: {
    height: 72
  },
  Circle: {
    width: 72,
    minWidth: 72,
    height: 72,
    fontSize: 10,
    color: 'white',
    display: 'flex',
    borderRadius: '50%',
    textAlign: 'center',
    fontWeight: 'bold',
    justifyContent: 'center',
    backgroundColor: COLORS.PRIMARY_LIGHT,
  },
  TopValueTitle: {
    color: COLORS.PRIMARY_LIGHT,
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 12,
  },
  // TOP 5
  TopFive: {
    marginBottom: 12,
    padding: 20,
  },
  // TOP 10
  TopTen: {
    padding: 20,
  },
  TopFiveValue: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.GRAY1,
    marginBottom: 8,
  },
  TopTenDescriptions: {
    fontSize: 10,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  TopTenDescription: {
    marginBottom: 8,
    fontSize: 9,
    color: COLORS.BLACK_LIGHT,
  }
});

// Interfaces
export type ReportPersonalValuesTopTenProps = {
  values: Array<{
    type: string;
  }>;
};

export type ReportPersonalValuesTopFiveProps = {
  values: Array<{
    type: string;
    definitionFreeText?: string;
    successCriteriaFreeText?: string;
    preventCriteriaFreeText?: string;
  }>;
};

export type ReportPersonalValuesProps = {
  data: Array<{
    name: string;
    tops: {
      5: ReportPersonalValuesTopFiveProps['values'];
      10: ReportPersonalValuesTopTenProps['values'];
    };
  }>;
}

const TopTen = ({ values = [] }: ReportPersonalValuesTopTenProps) => {
  const valuesRendered = values.map(({ type }, i) =>
    <View key={`value-${i}`} style={[styles.ValueCircle, { width: '10%' }]}>
      <View style={styles.Circle}>
        <Text>
          {type}
        </Text>
      </View>
    </View>
  )

  return (
    <View style={styles.TopTen}>
      <Text style={styles.TopValueTitle}>
        TOP 10 VALUES
      </Text>

      <View style={styles.ValuesRow}>
        {valuesRendered}
      </View>
    </View>
  );
}

const TopFive = ({ values = [] }: ReportPersonalValuesTopFiveProps) => {
  const valuesRendered = values.map(
    ({
       type,
       definitionFreeText = EMPTY_VALUE,
       successCriteriaFreeText = EMPTY_VALUE,
       preventCriteriaFreeText = EMPTY_VALUE
     },
     i
    ) =>
      <View key={`value-${i}`} style={styles.TopFiveValue} wrap={false}>
        <View style={styles.Circle}>
          <Text>{type}</Text>
        </View>

        <View style={styles.TopTenDescriptions}>
          <Heading level={3}>
            Define this value
          </Heading>

          <Text style={styles.TopTenDescription}>
            {definitionFreeText}
          </Text>

          <Heading level={3}>
            How would you know you achieved it?
          </Heading>

          <Text style={styles.TopTenDescription}>
            {successCriteriaFreeText}
          </Text>

          <Heading level={3}>
            What is preventing you from success?
          </Heading>

          <Text style={styles.TopTenDescription}>
            {preventCriteriaFreeText}
          </Text>
        </View>
      </View>
  );

  return (
    <View style={styles.TopFive}>
      <Text style={styles.TopValueTitle}>
        TOP 5 VALUES
      </Text>

      {valuesRendered}
    </View>
  );
}

export const ReportPersonalValuesDetail = ({ data = [] }: ReportPersonalValuesProps) => {
  const membersRendered = data.map(
    ({ name, tops }, i) => (
      <Page size="A4" orientation='landscape' style={commonStyles.Root} key={name}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          PERSONAL VALUES - {name}
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
        <TopTen values={tops['10']} />
        <TopFive values={tops['5']} />
      </Page>
    )
  );

  return (
    <>
      {membersRendered}
    </>
  );
}
