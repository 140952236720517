import { combineReducers } from 'redux';
import {
  accountsReducer,
  foaReducer,
  valuesReducer,
  qscoreReducer
} from '@qwealth/qdata';
import axios from './axios';
import advisorView from './advisorView';
import household from './household';
import legalEntities from './legalEntities';
import qbias from './qbias';
import notifications from './notifications';
import ref from './refData';
import auth from './auth';
import workshop from './workshop';
import { RESET_HOUSEHOLD } from '../actions/household';
import { RootState } from '../store';

const allReducers = combineReducers({
  accounts: accountsReducer,
  advisorView,
  axios,
  functionOfAssets: foaReducer,
  household,
  legalEntities,
  qbias,
  notifications,
  values: valuesReducer,
  refData: ref,
  workshop,
  qscore: qscoreReducer,
  auth
});

const rootReducer = (state: RootState, action: any) => {
  // reset household related states
  if (action.type === RESET_HOUSEHOLD) {
    state = {
      ...state,
      accounts: undefined,
      functionOfAssets: undefined,
      household: undefined,
      legalEntities: undefined,
      qbias: undefined,
      values: undefined,
      workshop: undefined,
    };
  }
  // @ts-ignore TODO fix me
  return allReducers(state, action);
};

export default rootReducer;
