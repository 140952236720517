import { calculateGuaranteedRetirementIncome } from '@qwealth/qcore';
import {
  IPerson,
  CurrentSpendingOutcome,
  RetirementAgeOutcome,
  RetirementSpendingOutcome,
  GriOutcome
} from '@qwealth/qdata';
import { CardMapValue } from 'data/types/workshop';

export const getCurrentSpending = (currentSpendingList?: CurrentSpendingOutcome[]): CardMapValue => {
  const outcome = currentSpendingList?.[0];
  return {
    primaryGoal: outcome,
    primaryValue: outcome?.currentSpendingValue,
    type: 'Current Spending'
  };
};

export const getRetirementAge = (
  retirementAgeOutcomes?: RetirementAgeOutcome[],
  primary?: IPerson,
  secondary?: IPerson
): CardMapValue => {
  const primaryOutcome = retirementAgeOutcomes?.find(
    (outcome) => outcome.attendeeQID === primary?.QID
  );
  const secondaryOutcome = retirementAgeOutcomes?.find(
    (outcome) => outcome.attendeeQID === secondary?.QID
  );

  return {
    primaryGoal: primaryOutcome,
    primaryValue: primaryOutcome?.retirementAgeValue,
    secondaryGoal: secondaryOutcome,
    secondaryValue: secondaryOutcome?.retirementAgeValue,
    type: 'Retirement: Age'
  };
};

export const getRetirementSpending = (
  retirementSpendingOutcomes: RetirementSpendingOutcome[]
): CardMapValue => {
  const defaultOutcome = retirementSpendingOutcomes.find(
    (outcome) => outcome.startAge === 'Retirement' && outcome.endAge === 'Death'
  );

  let primaryValue;
  if (defaultOutcome) {
    primaryValue = defaultOutcome.amount;
  }

  return {
    primaryGoal: defaultOutcome,
    primaryValue,
    type: 'Retirement: Spending'
  };
};

export const getGuranteedRetirementIncome = (
  griList: GriOutcome[],
  primary?: IPerson,
  secondary?: IPerson
): CardMapValue => {
  const primaryOutcomes = griList.filter((outcome) => outcome.attendeeQID === primary?.QID);
  const primaryValue = calculateGuaranteedRetirementIncome({
    guaranteedRetirementIncome: primaryOutcomes
  });
  const secondaryOutcomes = griList.filter((outcome) => outcome.attendeeQID === secondary?.QID);
  const secondaryValue = calculateGuaranteedRetirementIncome({
    guaranteedRetirementIncome: secondaryOutcomes
  });

  return {
    primaryGoal: primaryOutcomes.length ? primaryOutcomes : undefined,
    primaryValue,
    secondaryGoal: secondaryOutcomes.length ? secondaryOutcomes : undefined,
    secondaryValue,
    type: 'Guaranteed Retirement Income'
  };
};
