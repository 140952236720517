import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    height: 180
  },
  TextPanel: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 50,
    color: COLORS.WHITE,
    fontWeight: 'bold'
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 50,
    paddingHorizontal: 20,
    fontSize: 12,
    fontWeight: 'bold'
  }
});

const ReportTods = ({ data }: { data: AggregatedQScoreData }) => (
  <Page size="A4" orientation="landscape" style={[commonStyles.Root, { paddingBottom: 100 }]}>
    <View style={[commonStyles.Header, { marginBottom: 30 }]} fixed>
      <Text style={commonStyles.HeaderTitle}>To-dos</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View
      style={{
        paddingHorizontal: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}
    >
      <View style={styles.Content}>
        <View style={styles.TextPanel}>
          {/* @ts-ignore */}
          {data.strategies.map((strategy, index) => (
            <View style={styles.Row} key={index}>
              <View
                style={[
                  styles.NumberColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
                ]}
              >
                <Text>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.TextColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
                ]}
              >
                <Text>{strategy.toDosTitle}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  </Page>
);

export default ReportTods;
