import _ from 'lodash';
import {
  PUT_HOUSEHOLD,
  PUT_HOUSEHOLD_MEMBER,
  REMOVE_HOUSEHOLD_MEMBER,
  HOUSEHOLD_INITIALIZED,
  RESET_HOUSEHOLD,
  PUT_HOUSEHOLD_PARTNER
} from '../actions/household';
import { IHouseholdDto, IPartner, IPerson, MemberType } from '@qwealth/qdata';

type HouseholdState = {
  initialized: boolean;
  household?: IHouseholdDto | null;
  partner?: IPartner | null;
  members: Array<IPerson>,
  householdId: string;
}

const DEFAULT_STATE: HouseholdState = {
  initialized: false,
  household: null,
  partner: null,
  members: [],
  householdId: ''
};

const ORDER: MemberType[] = [
  'Primary Client',
  'Secondary Client',
  'Additional Client',
  'Non-Client Spouse',
  'Beneficiary',
  'Dependant'];

export default function (state = DEFAULT_STATE, action: any): HouseholdState {
  switch (action.type) {
    case RESET_HOUSEHOLD: {
      return DEFAULT_STATE;
    }
    case HOUSEHOLD_INITIALIZED: {
      const { householdId } = action;
      return { ...state, initialized: true, householdId };
    }
    case PUT_HOUSEHOLD: {
      const { household } = action;
      return { ...state, household };
    }
    case PUT_HOUSEHOLD_MEMBER: {
      const { person } = action;
      const { QID } = person;
      const { members } = state;
      const index = members.map((p) => p.QID).indexOf(QID);
      const newMembers =
        index > -1 ? members.map((p, idx) => (idx === index ? person : p)) : [...members, person];

      return {
        ...state,
        members: _.sortBy(newMembers, (p) => _.indexOf(ORDER, p.memberType))
      };
    }
    case PUT_HOUSEHOLD_PARTNER: {
      const { partner } = action;
      return { ...state, partner };
    }
    case REMOVE_HOUSEHOLD_MEMBER: {
      const { members } = state;
      const index = members.map((p) => p.QID).indexOf(action.person.QID);
      return {
        ...state,
        members: [...members.slice(0, index), ...members.slice(index + 1)]
      };
    }
    default:
      return state;
  }
}
