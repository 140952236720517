import { compose } from 'ramda';
import { createSelector } from 'reselect';

import { IPerson, MemberType } from '@qwealth/qdata';

import type { IState } from 'data/store';

export const selectHouseholdMembers = (state: IState) => state.household.members;
export const selectHouseholdId = (state: IState) => state.household.householdId;
export const selectHouseholdMemberIds = ({ household }: IState) =>
  household.initialized ? household.members.map(({ QID }) => QID as string) : [];

const findByType = (type: MemberType) => (members: Array<IPerson>) =>
  members.find(({ memberType }) => memberType === type) || {};

export const selectPrimary = compose(findByType('Primary Client'), selectHouseholdMembers);

export const selectSecondary = createSelector(selectHouseholdMembers, findByType('Secondary Client'));
