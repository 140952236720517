import { IPerson, Outcomes, RetirementAgeOutcome, SpendingInOutflowOutcome } from '@qwealth/qdata';
import { CardMapValue } from 'data/types/workshop';
import { getMultiplier, getYearMultiple } from '../data/DataUtil';
import { getAge } from 'components/Household/HouseholdUtil';

const DefaultRetirementAge = 65;

export const calculateTotalValue = (
  goal: SpendingInOutflowOutcome,
  age: number | undefined,
  retirementAge: number
) => {
  const { frequency, endAge, startAge } = goal;
  const amount = (goal.amount as number) ?? 0;
  let parsedStartAge = parseInt(startAge as string, 10) || undefined;
  let parsedEndAge = parseInt(endAge as string, 10) || undefined;
  if (startAge === 'Now') {
    parsedStartAge = age;
  } else if (startAge === 'Retirement') {
    parsedStartAge = retirementAge;
  }
  if (endAge === 'Retirement') {
    parsedEndAge = retirementAge;
  } else if (endAge === 'Death') {
    parsedEndAge = 95;
  }
  if (frequency === 'One Time Event') {
    return amount;
  }

  const annualMultiplier = getMultiplier(frequency);
  if (annualMultiplier > 1) {
    return parsedStartAge && parsedEndAge
      ? annualMultiplier * amount * (parsedEndAge - parsedStartAge)
      : 0;
  }

  const yearMultiplier = getYearMultiple(frequency);
  let totalValue = 0.0;
  if (parsedStartAge && parsedEndAge) {
    for (let simAge = parsedStartAge; simAge <= parsedEndAge; simAge += yearMultiplier) {
      totalValue += amount;
    }
  }

  return totalValue;
};

export const getRetirementFlows = (
  outcomeList: Outcomes,
  primary: IPerson | undefined,
  secondary: IPerson | undefined,
  retirementAgeOutcomes: RetirementAgeOutcome[],
  type: 'Post-retirement: Inflow' | 'Post-retirement: Outflow' | 'Pre-retirement: Spending'
): CardMapValue => {
  const flowOutcomes = outcomeList?.[type] ?? [];
  const primaryFlows = flowOutcomes.filter((outcome) => outcome.attendeeQID === primary?.QID);
  const secondaryFlows = flowOutcomes.filter((outcome) => outcome.attendeeQID === secondary?.QID);
  const primaryAge = primary && getAge(primary.dateOfBirth);
  const secondaryAge = secondary && getAge(secondary.dateOfBirth);

  let retirementGoal = retirementAgeOutcomes.find((o) => o?.attendeeQID === primary?.QID);
  let retirementAgeOutcomeValue = retirementGoal?.retirementAgeValue;
  const retirementAge = retirementAgeOutcomeValue ?? DefaultRetirementAge;
  retirementGoal = retirementAgeOutcomes.find((o) => o?.attendeeQID === secondary?.QID);
  retirementAgeOutcomeValue = retirementGoal?.retirementAgeValue;
  const secondaryRetirementAge = retirementAgeOutcomeValue ?? DefaultRetirementAge;

  const primaryValue = primaryFlows
    .map((g) => calculateTotalValue(g, primaryAge, retirementAge))
    .reduce((a, b) => a + b, 0);
  const secondaryValue = secondaryFlows
    .map((g) => calculateTotalValue(g, secondaryAge, secondaryRetirementAge))
    .reduce((a, b) => a + b, 0);

  return {
    primaryGoal: primaryFlows.length ? primaryFlows : undefined,
    primaryValue,
    secondaryGoal: secondaryFlows.length ? secondaryFlows : undefined,
    secondaryValue,
    type
  };
};
