import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IPerson,
  RetirementInflowsAndOutflowsData,
  loadGoalStrategies,
  loadNetWorthReport,
  loadRetirementInflowsAndOutflows,
  loadRetirementScore,
  NetWorthReportData,
  GoalStrategy
} from '@qwealth/qdata';

import { selectPrimary, selectSecondary } from 'data/selectors/householdSelectors';
import { getHouseholdId } from 'services/axiosService';

import { IState, useAppDispatch } from 'data/store';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

export const useAggregateQScoreData = (): AggregatedQScoreData | null => {
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [qScore, setQScore] = useState<string>('');
  const [retirementInflowsAndOutflows, setRetirementInflowsAndOutflows] = useState<
    Array<RetirementInflowsAndOutflowsData>
  >([]);
  const [netWorthReport, setNetWorthReport] = useState<Array<NetWorthReportData>>([]);
  const [strategies, setStrategies] = useState<Array<GoalStrategy>>([]);

  // COMMON
  // *******************
  const householdId = getHouseholdId();

  //@ts-ignore
  const primary = useSelector<IState, IPerson>(selectPrimary);
  //@ts-ignore
  const secondary = useSelector<IState, IPerson>(selectSecondary);

  useEffect(() => {
    Promise.all([
      dispatch(loadRetirementScore(householdId!)),
      // @ts-ignore
      dispatch(loadRetirementInflowsAndOutflows(householdId!)),
      dispatch(loadNetWorthReport(householdId!)),
      // @ts-ignore
      dispatch(loadGoalStrategies(householdId!))
    ]).then(([conquestRetirementScores, conquestInflowsAndOutflows, conquestNetWorthData, conquestStrategies]) => {
      // QScore info
      if (conquestRetirementScores.length > 0) {
        setQScore(conquestRetirementScores[0].formattedValue);
      }

      // RetirementInflowsAndOutflows Info
      if (conquestInflowsAndOutflows) {
        setRetirementInflowsAndOutflows(conquestInflowsAndOutflows);
      }

      // NetWorthReport Info
      if (conquestNetWorthData) {
        // Pick the reports till the age is 90
        setNetWorthReport(conquestNetWorthData.data);
      }

      // Strategies && Todos
      if (conquestStrategies) {
        setStrategies(conquestStrategies);
      }

      setReady(true);
    });
  }, [householdId, dispatch]);

  // LEGACY VALUE CHARTs
  // *******************
  const labels = netWorthReport.map((report) => report.year.toString());

  if (!ready) return null;

  return {
    qScore: qScore,
    personPrimary: primary,
    personSecondary: secondary,
    chartLegacyValue: {
      labels,
      datasets: [
        {
          label: 'Total Net Worth',
          data: netWorthReport.map(
            (networth) => {
              const totalNetWorthSummary = networth.summaries.find(({ item }) => item === 'TOTAL NET WORTH');
              if (totalNetWorthSummary) {
                return (totalNetWorthSummary.client ?? 0) + (totalNetWorthSummary.coClient ?? 0);
              }
              return 0;
            }
          ),
          backgroundColor: '#2671b5'
        }
      ]
    },
    retirementInflowsAndOutflows,
    netWorthReport,
    strategies
  };
};
