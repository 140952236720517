import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartDataset } from 'chart.js';

import { percentageTransform, resultsGraphTexts } from '@qwealth/qbias';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

const styles = StyleSheet.create({
  Section: {
    fontSize: 12,
    justifyContent: 'space-between',
    color: COLORS.BLACK_LIGHT,
    backgroundColor: COLORS.BACKGROUND.GRAY_LIGHT,
    marginVertical: 3,
    padding: 12,
    width: '100%'
  },
  SectionTitle: {
    fontSize: 14,
    marginBottom: 4,
    fontWeight: 'bold'
  },
  Content: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 48,
    color: COLORS.BLACK_LIGHT
  },
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    maxWidth: 400
  },
  TextPanel: {
    backgroundColor: COLORS.BACKGROUND.GRAY_LIGHT,
    flexDirection: 'column',
    padding: 20,
    fontSize: 10
  },
  InfoTitle: {
    fontSize: 12,
    color: COLORS.ORANGE
  },
  DetailsToShow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 12
  },
  DescriptionPanel: {
    width: '30%',
    fontSize: 9
  },
  SubTitle: {
    fontSize: 10,
    marginVertical: 8
  }
});

// Interfaces
export type ReportNetWorthProps = {
  data: Array<{
    title: string;
    content: string;
  }>;
};

export const QBiasLineView = ({ title, content }: { title: string; content: string }) => (
  <View style={styles.Section}>
    <Text style={styles.SectionTitle}>{title}</Text>
    <Text style={{ width: '100%' }}>{content}</Text>
  </View>
);

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

type ChartData = {
  labels: string[];
  datasets: ChartDataset[];
};

const DetailedData = ({ resultData, info, hardMath }: any) => (
  <View style={styles.TextPanel} wrap={false}>
    <View style={{ width: '100%' }}>
      <Text style={styles.InfoTitle}>
        {info.title} {hardMath}%
      </Text>
      <Text style={{ width: '100%' }} wrap={false}>
        {info.text}
      </Text>
    </View>
    <View style={styles.DetailsToShow}>
      <View style={styles.DescriptionPanel}>
        <Text style={styles.SubTitle}>Your Scores</Text>
        <Text style={{ display: 'flex', width: '100%' }}>
          {resultData.score.replaceAll('XX', hardMath)}
        </Text>
      </View>
      <View style={styles.DescriptionPanel}>
        <Text style={styles.SubTitle}>What Does it Mean?</Text>
        <Text>{resultData.mean}</Text>
      </View>
      <View style={styles.DescriptionPanel}>
        <Text style={styles.SubTitle}>What Do We do with this information?</Text>
        <Text>{resultData.whatDoWeDo}</Text>
      </View>
    </View>
  </View>
);

export const ReportQBiasContent = ({ data }: any) => {
  const sectionsRendered = resultsGraphTexts.map(({ title, text }, i) => (
    <QBiasLineView key={`section-${i}`} title={title} content={text} />
  ));

  const chartLegacyValueImg = useChartJsAsImage(
    // @ts-ignore
    crateChartJsConfig(
      'bar',
      ['Confirmation', 'Small Numbers', 'Loss Aversion & Certainty', 'Framing & Anchoring'],
      data?.[0] ?? []
    )
  );

  const chartLegacyValueImg2 = useChartJsAsImage(
    // @ts-ignore
    crateChartJsConfig(
      'bar',
      ['Confirmation', 'Small Numbers', 'Loss Aversion & Certainty', 'Framing & Anchoring'],
      data?.[1] ?? []
    )
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>QBIAS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber }) => pageNumber} />
      </View>

      {data?.length > 0 && <View style={{ padding: 20 }}>{chartLegacyValueImg}</View>}

      {data?.length > 0 &&
        resultsGraphTexts.map((info, index) => {
          const hardMath = (data[0][0].data?.[index] * 100).toFixed(0);
          // @ts-ignore
          const resultData = info[percentageTransform(Number(hardMath), info.tag)];
          return (
            resultData && (
              <DetailedData key={index} hardMath={hardMath} resultData={resultData} info={info} />
            )
          );
        })}
      {data?.length > 1 && <View style={{ padding: 20 }}>{chartLegacyValueImg2}</View>}
      {data?.length > 1 &&
        resultsGraphTexts.map((info, index) => {
          const hardMath = (data[1][0].data?.[index] * 100).toFixed(0);
          // @ts-ignore
          const resultData = info[percentageTransform(Number(hardMath), info.tag)];

          return (
            resultData && (
              <DetailedData key={index} hardMath={hardMath} resultData={resultData} info={info} />
            )
          );
        })}
      <View style={styles.Content} break>
        {sectionsRendered}
      </View>
    </Page>
  );
};
