export const EMPTY_VALUE = '—';

/*
 * TODO: FETCH COLORS FROM QCORE
 */
export const COLORS = {
  PRIMARY: '#481737',
  PRIMARY_LIGHT: '#7c3251',
  WHITE: '#ffffff',
  // GRAY: ['#f0f0f0', '#e3e2e2',],
  GRAY1: '#f0f0f0',
  GRAY2: '#e3e2e2',
  ORANGE: '#b2433c',
  PURPLE: '#714077',
  GREEN: '#7bb055',
  BLACK: '#000000',
  BLACK_LIGHT: '#222024',

  BACKGROUND: {
    PRIMARY_LIGHT: '#efeaef',
    GREEN_LIGHT: '#f1fbf0',
    GRAY_LIGHT: '#f7f7f8',
    ORANGE_LIGHT: '#faeeef'
  }
};
