import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { getNormalizedAccountNumber, toCurrency, sumValues } from '@qwealth/qcore';
import { AllocationDetailMap } from '@qwealth/qdata';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedAccountsData } from '../interfaces/AggregatedAccountsData';

export interface ReportReturnAssumptionProps {
  data: AggregatedAccountsData;
}

const fieldTypeMap = {
  Insurance: {
    typeField: 'insuranceType',
    detailField: 'insuranceAccountDetails'
  },
  Banking: {
    typeField: 'bankingType',
    detailField: 'bankingAccountDetails'
  },
  Investment: {
    typeField: 'investmentAccountSubType',
    detailField: 'investmentAccountDetails'
  },
  'Real Estate': {
    typeField: 'realEstateType',
    detailField: 'realEstateAccountDetails'
  },
  'Education or Disability': {
    typeField: 'accountSubType',
    detailField: 'educationDisabilityAccountDetails'
  },
  'Long Term Liability': {
    typeField: 'liabilityType',
    detailField: 'longTermLiabilityAccountDetails'
  },
  'Short Term Liability': {
    typeField: 'liabilityType',
    detailField: 'shortTermLiabilityAccountDetails'
  }
};

const styles = StyleSheet.create({
  Content: {
    padding: 30
  },
  BlockTitle: {
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 8,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY1
  },
  Column: {
    flex: 1,
    fontSize: 8
  },
  Cell: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    textAlign: 'center'
  },
  HeaderCell: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#555555'
  },
  BorderBottom: {
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY1
  },
  VerticalAlign: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const ReportReturnAssumption = ({ data }: ReportReturnAssumptionProps) => {
  const { accounts, members, legalEntities } = data;
  const AccountTypes = Object.keys(accounts);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>RETURN ASSUMPTIONS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        <Text
          style={{ fontSize: 16, color: COLORS.PRIMARY, textAlign: 'center', marginBottom: 25 }}
        >
          Now let’s look at each of your Investment Accounts and the Expected Rates of Return
        </Text>
        {AccountTypes.map((accountType, index) => (
          <View style={{ marginTop: 10 }} key={`assumption-${index}`}>
            <View style={styles.BlockTitle}>
              <Text>{accountType}</Text>
              <Text>{toCurrency(sumValues(accounts[accountType]))}</Text>
            </View>
            <View style={styles.Row}>
              <View style={{ width: 120 }}>
                <Text style={[styles.HeaderCell, { textAlign: 'left', paddingLeft: 5 }]}>
                  INSTITUTION
                </Text>
              </View>
              <View style={styles.Column}>
                <Text style={[styles.HeaderCell, { textAlign: 'left' }]}>TITLE</Text>
              </View>
              <View style={styles.Column}>
                <Text style={styles.HeaderCell}>OWNER</Text>
              </View>
              <View style={styles.Column}>
                <Text style={styles.HeaderCell}>ACCOUNT NUMBER</Text>
              </View>
              <View style={styles.Column}>
                <Text style={styles.HeaderCell}>TYPE/SUBTYPE</Text>
              </View>
              <View style={styles.Column}>
                <Text style={styles.HeaderCell}>RATE OF RETURN</Text>
              </View>
              <View style={styles.Column}>
                <Text style={styles.HeaderCell}>STANDARD DEVIATION</Text>
              </View>
              <View style={styles.Column}>
                <Text style={[styles.HeaderCell, { textAlign: 'right' }]}>ACCOUNT VALUE</Text>
              </View>
            </View>
            {accounts[accountType] &&
              accounts[accountType].map((account, idx) => {
                const {
                  institutionName,
                  accountTitle,
                  accountNumber,
                  ownershipType,
                  accountOwnersQID,
                  value,
                  assetAllocation
                } = account;
                let owner = '';

                if (ownershipType === 'Joint') {
                  owner = 'Joint';
                }

                const person = members.find(({ QID }) => QID === accountOwnersQID);
                if (person) {
                  owner = person.firstName;
                }

                const legalEntity = legalEntities.find(({ QID }) => QID === accountOwnersQID);
                if (legalEntity) {
                  owner = legalEntity.name;
                }

                const typeMetadata = fieldTypeMap[account.accountType];
                const subType =
                  typeMetadata &&
                  // @ts-ignore
                  account[typeMetadata.detailField] &&
                  // @ts-ignore
                  account[typeMetadata.detailField][typeMetadata.typeField];
                const accountType = `${account.accountType} / ${subType}`;
                const allocationDetail = assetAllocation && AllocationDetailMap[assetAllocation];
                const rateOfReturn = allocationDetail ? `${allocationDetail.growthRate} %` : '';
                const standardDeviation = allocationDetail ? `${allocationDetail.deviation} %` : '';

                return (
                  <View style={styles.Row} key={`account-${idx}`}>
                    <View style={{ width: 120 }}>
                      <Text style={[styles.Cell, { textAlign: 'left', paddingLeft: 5 }]}>
                        {institutionName}
                      </Text>
                    </View>
                    <View style={[styles.Column, { width: 120 }]}>
                      <Text style={[styles.Cell, { textAlign: 'left', paddingLeft: 10 }]}>
                        {accountTitle}
                      </Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'center' }]}>{owner}</Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'center' }]}>
                        {getNormalizedAccountNumber(members, accountNumber)}
                      </Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'center' }]}>{accountType}</Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'center' }]}>{rateOfReturn}</Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'center' }]}>
                        {standardDeviation}
                      </Text>
                    </View>
                    <View style={styles.Column}>
                      <Text style={[styles.Cell, { textAlign: 'right' }]}>
                        {accountType.indexOf('Liability') > -1
                          ? toCurrency(value * -1)
                          : toCurrency(value)}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        ))}
      </View>
    </Page>
  );
};

export default ReportReturnAssumption;
