import { StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../constant';

const commonStyles = StyleSheet.create({
  Root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 32,
  },
  Header: {
    width: '100%',
    height: 68,
    backgroundColor: COLORS.PRIMARY,
    padding: 20,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  HeaderTitle: {
    color: COLORS.WHITE,
    fontSize: 22,
    textTransform: 'uppercase',
  },
  PageNumber: {
    color: COLORS.WHITE,
    fontSize: 16,
  },
  BackImage: {
    position: 'absolute',
    width: '100%',
    // height: '100%',
    objectFit: 'cover',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  FooterLogo: {
    position: 'absolute',
    bottom: 20,
    right: 24,
    width: 24
  },
});

export default commonStyles;