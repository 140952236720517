import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { TableProps } from '@qwealth/pdf-support';
import { toCurrency } from '@qwealth/qcore';
import { RetirementInflowsAndOutflowsData } from '@qwealth/qdata';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Col: {
    flex: 1,
    fontSize: 8
  }
});

const createMasterDistributionCols = (): TableProps<RetirementInflowsAndOutflowsData>['cols'] => [
  { title: 'Year', val: ({ year }) => year },
  { title: 'Ages', val: ({ ages }) => ages },
  { title: 'Earned', val: ({ earned }) => toCurrency(earned, true) },
  {
    title: 'CPP/OAS\n/Pension',
    val: ({ cpp, oas, pension }) => toCurrency(cpp + oas + pension, true)
  },
  {
    title: 'Rental',
    val: ({ rental }) => toCurrency(rental, true)
  },
  {
    title: 'Corporate',
    val: ({ corporate }) => toCurrency(corporate, true)
  },
  {
    title: 'Minimums',
    val: ({ minimums }) => toCurrency(minimums, true)
  },
  {
    title: 'Registered',
    val: ({ registered }) => toCurrency(registered, true)
  },
  // {
  //   title: 'tfsa',
  //   val: ({ tfsa }) => tfsa
  // },
  {
    title: 'Non\nRegistered',
    val: ({ nonRegistered }) => toCurrency(nonRegistered, true)
  },
  {
    title: 'Other',
    val: ({ other }) => toCurrency(other, true)
  },
  {
    title: 'Debt',
    val: ({ debt }) => toCurrency(debt, true)
  },
  {
    title: 'Savings',
    val: ({ savings }) => toCurrency(savings, true)
  },
  {
    title: 'Expenses',
    val: ({ expenses }) => toCurrency(expenses, true)
  },
  // {
  //   title: 'Surplus',
  //   val: ({ surplus }) => surplus
  // },
  {
    title: 'Shortfalls',
    val: ({ shortfalls }) => toCurrency(shortfalls, true)
  }
];

const ReportInOutFlows = ({ data }: { data: AggregatedQScoreData }) => {
  const cols = createMasterDistributionCols();

  return (
    <Page size="A4" orientation="landscape" style={[commonStyles.Root, { paddingBottom: 40 }]}>
      <View style={[commonStyles.Header, { marginBottom: 25 }]} fixed>
        <Text style={commonStyles.HeaderTitle}>INFLOWS/OUTFLOWS REPORT</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report gives you a snapshot of what the year end value of your estate{' '}
          <Text style={styles.BoldFont}>MIGHT</Text> be. It is{' '}
          <Text style={styles.BoldFont}>highly unlikely</Text> that your year end values{' '}
          <Text style={styles.BoldFont}>will mirror this report</Text> however, this does provide
          you with a general idea of the flows of your assets. It is imperative that we{' '}
          <Text style={styles.BoldFont}>update this plan</Text> on a regular basis to measure how
          actual returns affect the year end legacy results.
        </Text>
        <View style={styles.Row}>
          {cols &&
            cols.map((col, index) => (
              <View key={`${2021 + index}`} style={styles.Col}>
                <View
                  style={{ height: 32, borderBottomWidth: 2, borderBottomColor: COLORS.GRAY1 }}
                  fixed
                >
                  <Text
                    style={{
                      color: COLORS.PRIMARY,
                      textTransform: 'uppercase',
                      fontWeight: 'semibold'
                    }}
                  >
                    {col.title}
                  </Text>
                </View>
                <View>
                  {data.retirementInflowsAndOutflows.map((retirementInflowsAndOutflow, index) => (
                    <View
                      key={`retirementInflowsAndOutflow${index}`}
                      style={{
                        height: 16,
                        borderBottomWidth: 2,
                        borderBottomColor: COLORS.GRAY1,
                        paddingTop: 4
                      }}
                    >
                      <Text>{col.val(retirementInflowsAndOutflow)}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportInOutFlows;
