import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { IPerson } from '@qwealth/qdata';

import { COLORS } from '../../constant';

const styles = StyleSheet.create({
  Root: {
    position: 'relative'
  },
  BackImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  MainTitle: {
    fontSize: 32,
    color: COLORS.WHITE
  },
  SubTitle: {
    fontSize: 18,
    marginTop: 100,
    marginBottom: 15,
    color: COLORS.WHITE
  },
  ClientName: {
    fontSize: 24,
    color: COLORS.WHITE,
    fontWeight: 700
  }
});

const ReportCover = ({
  personPrimary,
  personSecondary
}: {
  personPrimary?: IPerson;
  personSecondary?: IPerson;
}) => {
  return (
    <Page size="A4" orientation="landscape" style={styles.Root}>
      <Image style={styles.BackImage} src="/assets/qw-cover.jpg" />
      <View style={styles.wrapper}>
        <Text style={styles.MainTitle}>
          YOUR{'  '}
          <Text style={{ fontSize: 52 }}>Q</Text>SCORE
        </Text>
        <Text style={styles.SubTitle}>PREPARED FOR:</Text>
        {personPrimary && (
          <Text style={styles.ClientName}>
            {personPrimary.firstName} {personPrimary.lastName}
          </Text>
        )}
        {personSecondary && (
          <Text style={styles.ClientName}>
            {personSecondary.firstName} {personSecondary.lastName}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default ReportCover;
