import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';

const styles = StyleSheet.create({
  Root: {
    position: 'relative'
  },
  BackImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  MainTitle: {
    fontSize: 32,
    color: COLORS.WHITE
  },
  SubTitle: {
    fontSize: 18,
    marginTop: 100,
    marginBottom: 15,
    color: COLORS.WHITE
  },
  ClientName: {
    fontSize: 24,
    color: COLORS.WHITE
  }
});

export const ReportCover = ({
  primaryName,
  secondaryName
}: {
  primaryName?: string;
  secondaryName?: string;
}) => {
  return (
    <Page size="A4" orientation="landscape" style={styles.Root}>
      <Image style={styles.BackImage} src="/assets/qw-cover.jpg" />
      <View style={styles.wrapper}>
        <Text style={styles.MainTitle}>JOURNEY BASED PLANNING</Text>
        <Text style={styles.SubTitle}>PREPARED FOR:</Text>
        {primaryName && <Text style={styles.ClientName}>{primaryName}</Text>}
        {secondaryName && <Text style={styles.ClientName}>{secondaryName}</Text>}
      </View>
    </Page>
  );
};
