import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PDFViewer, Document, Font } from '@react-pdf/renderer';
import { find, sumBy, groupBy } from 'lodash';
import { getNormalizedAccountNumber, useDispatchOnMount } from '@qwealth/qcore';
import {
  IPerson,
  initValues,
  initFOA,
  initializeHouseholdAccounts,
  loadWorkshop,
  selectAssetAccounts,
  selectAssetAccountTotal,
  selectLiabilityAccounts,
  selectLiabilityAccountTotal,
  selectTotalNetWorth
} from '@qwealth/qdata';

import { ReportCover } from '../chunks/ReportCover';
import { ReportOverview } from '../chunks/ReportOverview';
import { ReportContent } from '../chunks/ReportContent';
import { ReportHouseholdMembers } from '../chunks/ReportHouseholdMembers';
import { ReportNetWorthV2, ReportNetWorthProps } from '../chunks/ReportNetWorthV2';
import { ReportNetWorthDetails } from '../chunks/ReportNetWorthDetails';
import { ReportFunctionsOfAssets } from '../chunks/ReportFunctionsOfAssets';
import {
  ReportFunctionsOfAssetsContent,
  ReportFunctionOfAssetsProps
} from '../chunks/ReportFunctionsOfAssetsContent';
import { ReportPersonalValuesV2 } from '../chunks/ReportPersonalValuesV2';
import { ReportPersonalValuesDetail } from '../chunks/ReportPersonalValuesDetail';
import { ReportQBias } from '../chunks/ReportQBias';
import { ReportQBiasContent } from '../chunks/ReportQBiasContent';
import { ReportBaselineAssumption } from '../chunks/ReportBaselineAssumption';
import { ReportQRoutesV2 } from '../chunks/ReportQRoutesV2';

import { ReportQRoutesProps } from '../chunks/ReportQRoutes';
import { ReportHouseholdProps } from '../chunks/ReportHousehold';
import { ReportBaselinePlanProps } from '../chunks/ReportBaselinePlan';
import { ReportPersonalValuesProps } from '../chunks/ReportPersonalValues';
import buildBaselinePlan from 'services/baselinePlanService';

import './index.scss';

import { getQBias } from 'data/actions/householdQbias';
import { AppThunkDispatch, RootState } from 'data/store';

Font.registerHyphenationCallback((word: string) => word.split(''));
Font.register({
  family: 'Raleway',
  fonts: [
    { src: 'fonts/Raleway/Raleway-Light.ttf' },
    { src: 'fonts/Raleway/Raleway-Medium.ttf' },
    { src: 'fonts/Raleway/Raleway-Regular.ttf' },
    { src: 'fonts/Raleway/Raleway-Bold.ttf' }
  ]
});

// Interfaces
type ReportBuildProps = {
  dataQRoutes: ReportQRoutesProps['data'];
  dataNetWorth: ReportNetWorthProps;
  dataHousehold: ReportHouseholdProps['data'];
  dataBaselinePlan: ReportBaselinePlanProps['data'];
  dataPersonalValues: ReportPersonalValuesProps['data'];
  dataFunctionOfAssets: ReportFunctionOfAssetsProps['data'];
  primaryName?: string;
  secondaryName?: string;
  dataQBiasValues: any;
};

const ReportBuild = ({
  dataQRoutes,
  dataNetWorth,
  dataHousehold,
  dataBaselinePlan,
  dataPersonalValues,
  dataFunctionOfAssets,
  dataQBiasValues,
  primaryName,
  secondaryName
}: ReportBuildProps) => {
  return (
    <Document>
      <ReportCover primaryName={primaryName} secondaryName={secondaryName} />
      <ReportOverview />
      <ReportContent primaryName={primaryName} secondaryName={secondaryName} />
      <ReportHouseholdMembers data={dataHousehold} />
      <ReportNetWorthV2 {...dataNetWorth} />
      <ReportNetWorthDetails {...dataNetWorth} />
      <ReportPersonalValuesV2 />
      <ReportPersonalValuesDetail data={dataPersonalValues} />
      <ReportFunctionsOfAssets />
      <ReportFunctionsOfAssetsContent data={dataFunctionOfAssets} />
      <ReportQBias />
      <ReportQBiasContent data={dataQBiasValues} />
      <ReportBaselineAssumption data={dataBaselinePlan} secondaryName={secondaryName} />
      <ReportQRoutesV2 data={dataQRoutes} />
    </Document>
  );
};

export const ReportSummary = () => {
  const [ready, setReady] = useState(false);
  const dispatch: AppThunkDispatch = useDispatch();

  // @ts-ignore
  const accounts: Array<Account> = useSelector((state: RootState) => state.accounts);
  const household = useSelector((state: RootState) => state.household);
  const legalEntities = useSelector((state: RootState) => state.legalEntities);
  const values = useSelector((state: RootState) => state.values);
  const functionOfAssets = useSelector((state: RootState) => state.functionOfAssets);

  const { initialized, householdId } = household;
  // @ts-ignore
  const members: Array<IPerson> = household.members;
  // @ts-ignore
  const qbias = useSelector((state) => state.qbias);
  const primaryMember = members.find((member) => member.memberType === 'Primary Client');
  const secondaryMember = members.find((member) => member.memberType === 'Secondary Client');
  const primaryName = `${primaryMember?.firstName} ${primaryMember?.lastName}`;
  const secondaryName = `${secondaryMember?.firstName} ${secondaryMember?.lastName}`;
  // @ts-ignore
  const [assetNames, assetAccountsByCategories] = useSelector(selectAssetAccounts);
  // @ts-ignore
  const [liabilityNames, liabilityAccountsByCategories] = useSelector(selectLiabilityAccounts);
  const totalAssets = useSelector(selectAssetAccountTotal);
  const totalLiabilities = useSelector(selectLiabilityAccountTotal);
  const totalNetWorth = useSelector(selectTotalNetWorth);

  const dataQBiasValues =
    qbias?.length >= 1
      ? qbias?.map((item: any, index: number) => [
          {
            backgroundColor: '#cc433c',
            categoryPercentage: 0.5,
            label: index === 0 ? 'Primary' : 'Secondary',
            data: [
              Number(item?.confirmationScore || 0),
              Number(item?.smallNumbersScore || 0),
              Number(item?.lossAversionScore || 0),
              Number(item?.framingAnchoringScore || 0)
            ]
          },
          {
            backgroundColor: '#e6a4a1',
            categoryPercentage: 0.5,
            label: 'Population',
            data: [
              Number(item?.qbiasPopulationScores?.populationconfirmationScore || 0),
              Number(item?.qbiasPopulationScores?.populationsmallNumbersScore || 0),
              Number(item?.qbiasPopulationScores?.populationlossAversionScore || 0),
              Number(item?.qbiasPopulationScores?.populationframingAnchoringScore || 0)
            ]
          }
        ])
      : [];

  const getOwnerName = (ownerId: string): string => {
    const isLegalEntity = ownerId.includes('-3-');
    let accountOwner = 'N/A';
    if (isLegalEntity) {
      // @ts-ignore TODO: fix me
      const found = legalEntities.find((entity) => entity.QID === ownerId);
      if (found) {
        accountOwner = found.name;
      }
    } else {
      const found = members.find((member) => member.QID === ownerId);
      if (found) {
        accountOwner = found.firstName;
      }
    }
    return accountOwner;
  };

  // @ts-ignore
  const assetAccountsByCategoriesFormatted = assetAccountsByCategories?.map(
    // @ts-ignore
    (assetAccounts) =>
      // @ts-ignore
      assetAccounts.map((assetAccount) => ({
        ...assetAccount,
        accountNumber: getNormalizedAccountNumber(members, assetAccount.accountNumber),
        accountOwner: getOwnerName(assetAccount.accountOwnersQID)
      }))
  );

  // @ts-ignore
  const liabilityAccountsByCategoriesFormatted = liabilityAccountsByCategories?.map(
    // @ts-ignore
    (liabilityAccounts) =>
      // @ts-ignore
      liabilityAccounts.map((liabilityAccount) => ({
        ...liabilityAccount,
        accountNumber: getNormalizedAccountNumber(members, liabilityAccount.accountNumber),
        accountOwner: getOwnerName(liabilityAccount.accountOwnersQID)
      }))
  );

  useEffect(() => {
    if (initialized && !ready) {
      // FIXME: Add loading flags to reducers to control initialization
      const waitAll: Array<Promise<any>> = [dispatch(initFOA()), dispatch(getQBias())];

      members.forEach(({ QID }) => {
        waitAll.push(dispatch(initValues(householdId, QID)));
      });
      waitAll.push(dispatch(initializeHouseholdAccounts(householdId, false)));

      Promise.all(waitAll).then(() => {
        setReady(true);
      });
    }
  }, [ready, setReady, dispatch, members, initialized, householdId]);

  // NET WORTH DATA TRANSFORM
  const dataNetWorth: ReportNetWorthProps['data'] = useMemo(
    () =>
      Object.entries(groupBy(accounts, 'accountType')).map(([accountTitle, entries]) => {
        const children = entries.map(({ accountOwnersQID, ...other }) => {
          const member = find(members, { id: accountOwnersQID });

          return {
            ...other,
            fullName: member
              ? // @ts-ignore
                `${member.firstName} ${member.lastName}`
              : 'N/A'
          };
        });

        return {
          accountTitle,
          children,
          total: sumBy(children, 'value')
        };
      }),
    [accounts, members]
  );

  const dataNetWorthProps = {
    data: dataNetWorth,
    totalNetWorth,
    totalAssets,
    totalLiabilities,
    assetNames: assetNames || [],
    liabilityNames: liabilityNames || [],
    assetAccountsByCategories: assetAccountsByCategoriesFormatted,
    liabilityAccountsByCategories: liabilityAccountsByCategoriesFormatted,
    members
  };

  // BASE LINE DATA TRANSFORM
  const { initializedWorkshop, workshop } = useSelector((state: RootState) => state.workshop);
  useDispatchOnMount(loadWorkshop, householdId, initializedWorkshop ? undefined : true);

  const dataBaselinePlan: ReportBaselinePlanProps['data'] = useMemo(
    () => buildBaselinePlan(members, accounts, workshop.outcomes, 'report') as any,
    [members, accounts, workshop.outcomes]
  );

  // PERSONAL VALUES DATA TRANSFORM
  // @ts-ignore
  const dataPersonalValues: ReportPersonalValuesProps['data'] = useMemo(
    () =>
      Object.entries(values)
        .map(([userId, value]) => {
          const member = find(members, { QID: userId });

          const tops = {
            // RANK TOP5
            // @ts-ignore
            5: value.rankTop5.map(({ type }) => ({
              type,
              // @ts-ignore
              ...find(value.detailedValues, { type })
            })),

            // RANK TOP10
            // @ts-ignore
            10: value.rankTop10.map(
              // @ts-ignore
              ({ type }) => ({ type })
            )
          };
          // @ts-ignore
          if (['Primary Client', 'Secondary Client'].includes(member?.memberType)) {
            return {
              tops,
              // @ts-ignore
              name: member?.firstName ?? 'N/A'
            };
          }
          return null;
        })
        .filter((value) => value !== null),
    [values, members]
  );

  // QROUTE DATA TRANSFORM
  // TODO: Paul, please update this later. we no longer have a concept of simulation
  const dataQRoutes: ReportQRoutesProps['data'] = [];

  // @ts-ignore
  const dataQRoutesFormatted = dataQRoutes?.map(
    // @ts-ignore
    (dataQRoute) =>
      // @ts-ignore
      ({
        ...dataQRoute,
        attendeeQID:
          // @ts-ignore
          members.find((member) => member.QID === dataQRoute.attendeeQID).firstName || 'N/A'
      })
  );

  // HOUSEHOLD DATA TRANSFORM
  const dataHousehold: ReportHouseholdProps['data'] = members;

  // HOUSEHOLD DATA TRANSFORM
  const dataFunctionOfAssets: ReportFunctionOfAssetsProps['data'] = functionOfAssets;

  // ---
  // @ts-ignore
  if (!household.initialized || !ready) return null;

  return (
    <div className="reports-summary">
      <PDFViewer className="pdf-viewer">
        <ReportBuild
          primaryName={primaryName}
          secondaryName={secondaryName}
          dataQRoutes={dataQRoutesFormatted}
          dataNetWorth={dataNetWorthProps}
          dataHousehold={dataHousehold}
          dataBaselinePlan={dataBaselinePlan}
          dataPersonalValues={dataPersonalValues}
          dataFunctionOfAssets={dataFunctionOfAssets}
          dataQBiasValues={dataQBiasValues}
        />
      </PDFViewer>
    </div>
  );
};
