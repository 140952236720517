import { DISMISS_NOTIFICATION, PUT_NOTIFICATION } from '../actions/notifications';

export default function (state = [], action) {
  const { notificationUuid } = action;
  switch (action.type) {
    case PUT_NOTIFICATION: {
      const { level, text, timeStamp } = action;
      const target = state.find((n) => n.notificationUuid === notificationUuid);
      const notification = { text, level, notificationUuid, timeStamp };
      return target
        ? state.map((n) => (n.notificationUuid === notificationUuid
          ? { ...n, ...notification }
          : n))
        : [...state, notification];
    }
    case DISMISS_NOTIFICATION: {
      return state.filter((n) => n.notificationUuid !== notificationUuid);
    }
    default:
      return state;
  }
}
