import { sortBy, indexOf } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { ILegalEntityDTO, putEntity, removeEntity } from '@qwealth/qdata';

const ORDER = ['Corporate', 'Trust'];
const legalEntitiesReducer = createReducer([] as ILegalEntityDTO[], (builder) => {
  builder.addCase(putEntity, (state, action) => {
    const entity = action.payload;
    const idx = state.findIndex((e) => e.QID === entity.QID);
    let legalEntities = [...state, entity];
    if (idx > -1) {
      legalEntities = [...state.slice(0, idx), entity, ...state.slice(idx + 1)];
    }

    return sortBy(legalEntities, (e) => indexOf(ORDER, e.entityType));
  });
  builder.addCase(removeEntity, (state, action) => {
    return state.filter((e) => e.QID !== action.payload);
  });
});

export default legalEntitiesReducer;
