import axios from 'axios';
import {
  errorHandler,
  getHouseholdId,
  REACT_APP_QWEALTH_API, responseMapper,
  // getPartnerShortcode,
  // notifyError,
  // REACT_APP_QWEALTH_QC_API,
  // responseMapper
} from '../../services/axiosService';

export const PUT_HOUSEHOLD_QBIAS = 'PUT_HOUSEHOLD_QBIAS';

// @ts-ignore
export const getQBias = () => async dispatch => {
  const householdId = getHouseholdId();
  try {
    const qbias = await axios.get(`${REACT_APP_QWEALTH_API}/qbias/household/${householdId}`)
      .then(responseMapper);
    dispatch({
      type: PUT_HOUSEHOLD_QBIAS,
      qbias
    });
  } catch (e) {
    errorHandler(dispatch);
  }
};
