import { SET_USER } from '../actions/user.ts';

export default function (state = { user: {} }, action) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.user };
    }
    default:
      return state;
  }
}
