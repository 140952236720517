import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

import questions from '../../../FunctionOfAssets/questions';
import { FieldSetProps } from '@qwealth/pdf-support'

// Interfaces
type ReportFunctionOfAssetsItemType = {
  functionGoal: string;
  functionGoal2: string;
  functionDefinition: string;
  functionDefinition2: string;
}

export type ReportFunctionOfAssetsProps = {
  data: Array<ReportFunctionOfAssetsItemType & {
    type: 'Sharing' | 'Protection' | 'Enhancement';
  }>
}


const FIELD_SETS: { [key: string]: FieldSetProps<ReportFunctionOfAssetsItemType>['fields'] } = {
  sharing: [
    {
      name: questions.Sharing.definition,
      val: ({ functionDefinition }) => functionDefinition
    },
    {
      name: questions.Sharing.goal,
      val: ({ functionGoal }) => functionGoal
    }
  ],
  protection: [
    {
      name: questions.Protection.definition,
      val: ({ functionDefinition }) => functionDefinition
    },
    {
      name: questions.Protection.goal,
      val: ({ functionGoal }) => functionGoal
    },
    {
      name: questions.Protection.definition2,
      val: ({ functionDefinition2 }) => functionDefinition2
    },
    {
      name: questions.Protection.goal,
      val: ({ functionGoal2 }) => functionGoal2
    }
  ],
  enhancement: [
    {
      name: questions.Enhancement.definition,
      val: ({ functionDefinition }) => functionDefinition
    },
    {
      name: questions.Enhancement.goal,
      val: ({ functionGoal }) => functionGoal
    },
    {
      name: questions.Enhancement.definition2,
      val: ({ functionDefinition2 }) => functionDefinition2
    },
    {
      name: questions.Enhancement.goal,
      val: ({ functionGoal2 }) => functionGoal2
    }
  ]
};

const FIELD_META_DATA = {
  sharing: {
    text: 'The ability to share or actually give those benefits to others to enhance their lives, either in the moment or as a legacy.',
    color: COLORS.ORANGE,
  },
  protection: {
    text: 'Provide you a layer of protection insulating you from the potential pitfalls of life as you define them.',
    color: COLORS.PURPLE,
  },
  enhancement: {
    text: 'Enhance your lifestyle by tangible benefits as well as greater options and choices.',
    color: COLORS.GREEN,
  },
}

const styles = StyleSheet.create({
  Content: {
    paddingVertical: 20,
    paddingHorizontal: 32,
  },
  FIELD: {

  },
  TypePanel: {
    padding: 10,
    color: COLORS.WHITE,
  },
  TextPanel: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  TypeTitle: {
    textTransform: 'capitalize',
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 'bold',
  },
  TypeText: {
    fontSize: 12
  },
  Item: {
    padding: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.GRAY1,
    width: '50%',
  },
  ItemTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: COLORS.BLACK,
  },
  ItemText: {
    fontSize: 12,
    color: COLORS.BLACK_LIGHT
  }

});

export const ReportFunctionsOfAssetsContent = ({ data = [] }: ReportFunctionOfAssetsProps) => {
  return (
    <Page size="A4" orientation='landscape' style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src='/assets/q-logo.png' fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>
          FUNCTIONS OF ASSETS
        </Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        {Object.keys(FIELD_SETS).map(type => (
          <View style={styles.FIELD} key={type}>
            {/*
            // @ts-ignore */}
            <View style={[styles.TypePanel, {backgroundColor: FIELD_META_DATA[type].color}]}>
              <Text style={styles.TypeTitle}>
                {type}
              </Text>
              <Text style={styles.TypeText}>
              {/*
              // @ts-ignore */}
                {FIELD_META_DATA[type].text}
              </Text>
            </View>
            <View style={styles.TextPanel}>
              {/*
              // @ts-ignore */}
              {FIELD_SETS[type].map(({name, val}, index) => (
                <View style={styles.Item} key={`${name}-${index}`}>
                  <Text style={styles.ItemTitle}>
                    {name}
                  </Text>
                  <Text style={styles.ItemText}>
                  {/*
                  // @ts-ignore */}
                    {data.length ? val(data.find(item => item.type?.toLowerCase() === type)): null}
                  </Text>
                </View>
              ))
              }
            </View>
          </View>
        ))}
      </View>
    </Page>
  );
};