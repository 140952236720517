import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { httpClientIntegration } from '@sentry/integrations';
import { ThemeProvider } from '@qwealth/qcore';
import { QContext } from '@qwealth/qdata';
import { MsalProvider } from '@azure/msal-react';
import 'holderjs';

import { msalInstance } from 'services/msalService';
import { errorHandler } from './services/axiosService';

import App from './App';
import store from './data/store';

import './theme.scss';
import './index.css';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const { REACT_APP_VERSION, REACT_APP_ENV } = process.env;

console.log(`Current app version is: ${REACT_APP_VERSION}`);

QContext.setErrorHandler(errorHandler);

Sentry.init({
  dsn: 'https://acbbd8dd32d9460cbe37070392477838@o1168654.ingest.sentry.io/6305691',
  integrations: [new BrowserTracing(), httpClientIntegration()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_ENV,
});

const {
  REACT_APP_QWEALTH_AUTH_REGION,
  REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
  REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  REACT_APP_QWEALTH_S3_BUCKET,
  REACT_APP_QWEALTH_IDENTITY_POOL_ID,
} = process.env;

Amplify.configure({
  Auth: {
    identityPoolId: REACT_APP_QWEALTH_IDENTITY_POOL_ID,
    region: REACT_APP_QWEALTH_AUTH_REGION,
    userPoolId: REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_QWEALTH_S3_BUCKET,
      region: REACT_APP_QWEALTH_AUTH_REGION,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>
);
