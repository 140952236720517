import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import _ from 'lodash';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

// Common Components
import { TableProps } from '@qwealth/pdf-support';
import { toCurrency } from '@qwealth/qcore';

const styles = StyleSheet.create({
  Content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 24,
    color: COLORS.BLACK_LIGHT
  },
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    marginVertical: 24,
    textAlign: 'center',
    width: '100%'
  },
  HighLight: {
    // color: COLORS.PRIMARY,
  },
  TitleLine: {
    fontSize: 14,
    marginVertical: 8
  },
  HeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 24
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 2,
    borderStyle: 'solid',
    borderColor: COLORS.GRAY1,
    minHeight: 24
  },
  Cell: {
    paddingVertical: 4,
    fontSize: 10
  }
});

type ReportBaseLineItemType = {
  [key: string]: any;
  type?: string;
  primaryValue: string;
  secondaryValue: string;
};

// Interfaces
export type ReportBaselinePlanProps = {
  data: {
    [key: string]: Array<{
      title: string;
      value: ReportBaseLineItemType;
    }>;
  };
  secondaryName?: string;
};

const COLUMNS: TableProps<ReportBaseLineItemType>['cols'] = [
  { title: 'Title', val: ({ title }) => title || '' },
  {
    title: 'Primary',
    val: ({ title, primaryValue }) =>
      title !== 'Retirement Age' ? toCurrency(Number(primaryValue || 0), true) || '-' : primaryValue
  },
  {
    title: 'Secondary',
    val: ({ title, secondaryValue }) =>
      title !== 'Retirement Age'
        ? toCurrency(Number(secondaryValue || 0), true) || '-'
        : secondaryValue
  }
];

export const StyledTable = ({
  cols,
  data
}: {
  cols: TableProps<ReportBaseLineItemType>['cols'];
  data: any[];
}) => (
  <View style={{ width: '100%' }}>
    <View style={styles.HeaderRow}>
      {cols.map((col, index) => (
        <View
          key={`header-${index}`}
          style={[styles.HeaderRow, { width: `${100 / cols.length}%` }]}
        >
          <Text
            style={[
              styles.Cell,
              {
                textAlign: index === 0 ? 'left' : index === cols.length - 1 ? 'right' : 'center',
                width: '100%'
              }
            ]}
          >
            {col.title}
          </Text>
        </View>
      ))}
    </View>
    {data.map((row, rowIndex) => (
      <View style={styles.Row} key={`row-${rowIndex}`}>
        {cols.map((col, colIndex) => (
          <View
            key={`header-${colIndex}`}
            style={[styles.HeaderRow, { width: `${100 / cols.length}%` }]}
          >
            <Text
              style={[
                styles.Cell,
                {
                  textAlign:
                    colIndex === 0 ? 'left' : colIndex === cols.length - 1 ? 'right' : 'center',
                  width: '100%'
                }
              ]}
            >
              {col.val(row)}
            </Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

export const ReportBaselineAssumption = ({ data = {}, secondaryName }: ReportBaselinePlanProps) => {
  const fieldSetsRendered = Object.entries(data).map(([title, fieldSets], i) => (
    <View key={`section-${i}`} wrap={false}>
      <Text style={styles.TitleLine}>{title}</Text>
      <StyledTable
        cols={secondaryName ? COLUMNS : COLUMNS.slice(0, 2)}
        data={fieldSets.map(({ title, value }) => ({ ...value, title }))}
      />
    </View>
  ));

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>BASELINE JOURNEY ASSUMPTIONS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Content}>
        <Text style={styles.Title}>
          Time to collate the data we have collected and outline the Assumptions {'\r\n'}
          that will comprise our <Text style={styles.HighLight}>‘Journey Based Plan’</Text>.
        </Text>
        {!_.isEmpty(data) && fieldSetsRendered}
      </View>
    </Page>
  );
};
