import { AnyAction, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Account, IPerson, Household, ValueState } from '@qwealth/qdata';

import rootReducer from './reducers';
import { useDispatch } from 'react-redux';

export interface IState {
  accounts: Array<Account>;
  household: {
    household: Household;
    initialized: boolean;
    householdId: string;
    members: Array<IPerson>;
  };
  values: ValueState;
}

const store: Store = configureStore({ reducer: rootReducer });

// @ts-ignore
window.store = store; // TODO: for debugging. remove in production

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const useAppDispatch: () => AppThunkDispatch = useDispatch

export default store;
