import { TransactionOutcome } from '@qwealth/qdata';
import { CardMapItem } from 'data/types/workshop';

export const getTransactionsForPlan = (outcomes?: TransactionOutcome[]): CardMapItem[] => [
  {
    title: 'Transactions',
    value: {
      primaryGoal: outcomes,
      type: 'Transaction'
    }
  }
];

export const getTransactionsForReport = (outcomes?: TransactionOutcome[]): CardMapItem[] => {
  let cards: CardMapItem[] = [
    {
      title: 'No outcomes',
      value: {
        primaryValue: undefined
      }
    }
  ];
  if (outcomes?.length) {
    cards = outcomes.map((goal) => ({
      title: goal.transactionType,
      value: {
        primaryValue: goal.amount
      }
    }));
  }
  return cards;
};
