import { getLegacyBalance } from '@qwealth/qcore';
import { IPerson, Account, Outcomes } from '@qwealth/qdata';
import { getRetirementSpendingGoals } from '@qwealth/qworkshop';

import { CardMap } from 'data/types/workshop';
import {
  getRetirementAge,
  getGuranteedRetirementIncome,
  getRetirementSpending,
  getCurrentSpending
} from './retirementPlanHelper';
import {
  getNonRegisteredRetirementSavings,
  getRegisteredRetirementSavings,
  getTaxFreeRetirementSavings,
  getTotalRetirementSavings
} from './retirementSavingHelper';
import { getRetirementFlows } from './retirementFlowHelper';
import { getEducationGoalsForReport, getEducationGoalsForPlan } from 'data/outcome/educationHelper';
import { getTransactionsForPlan, getTransactionsForReport } from 'data/outcome/transactionHelper';

export default function buildBaselinePlan(
  members: IPerson[],
  accounts: Account[],
  outcomeList: Outcomes,
  context: 'baselinePlan' | 'report'
): CardMap {
  const primary = members.find((member) => member.memberType === 'Primary Client');
  const secondary = members.find((member) => member.memberType === 'Secondary Client');

  const currentSpending = getCurrentSpending(outcomeList?.['Current Spending']);
  const retirementSpending = getRetirementSpending(outcomeList?.['Retirement: Spending'] ?? []);
  const retirementIncome = getGuranteedRetirementIncome(
    outcomeList?.['Guaranteed Retirement Income'] ?? [],
    primary,
    secondary
  );

  const annualWithdrawlFromRetirementSavings = {
    ...(retirementSpending.primaryValue &&
      retirementIncome.primaryValue && {
        primaryValue:
          (retirementSpending.primaryValue || 0) -
          (retirementIncome.primaryValue || 0) -
          (retirementIncome.secondaryValue || 0)
      }),
    readOnly: true
  };

  const retirementAgeOutcomes = outcomeList?.['Retirement: Age'] ?? [];
  const retirementOutflows = getRetirementFlows(
    outcomeList,
    primary,
    secondary,
    retirementAgeOutcomes,
    'Post-retirement: Outflow'
  );
  const retirementInflows = getRetirementFlows(
    outcomeList,
    primary,
    secondary,
    retirementAgeOutcomes,
    'Post-retirement: Inflow'
  );
  const preRetirementSpending = getRetirementFlows(
    outcomeList,
    primary,
    secondary,
    retirementAgeOutcomes,
    'Pre-retirement: Spending'
  );

  const retirementSpendingGoals = getRetirementSpendingGoals(
    outcomeList?.['Retirement: Spending'] ?? []
  );
  const defaultRetirementSpendingAmount = retirementSpendingGoals.length
    ? retirementSpendingGoals[0].amount
    : 0;
  const educationGoals =
    context === 'baselinePlan'
      ? getEducationGoalsForPlan(members, outcomeList?.['Education Goal'])
      : getEducationGoalsForReport(outcomeList?.['Education Goal'] ?? [], members);
  const transactionGoals =
    context === 'baselinePlan'
      ? getTransactionsForPlan(outcomeList?.['Transaction'])
      : getTransactionsForReport(outcomeList?.['Transaction']);
  const legacyGoal = outcomeList['Legacy: End Value']?.[0];

  const primaryLabel = primary && `${primary.firstName}:`;
  const secondaryLabel = secondary && `${secondary.firstName}:`;

  return {
    'Retirement Plan Info': [
      {
        title: 'Retirement Age',
        label: primaryLabel,
        label2: secondaryLabel,
        value: getRetirementAge(outcomeList?.['Retirement: Age'], primary, secondary)
      },
      {
        title: 'Current Spending',
        hideSecondary: true,
        value: currentSpending
      },
      {
        title: 'Retirement Spending',
        value: {
          primaryGoal: retirementSpendingGoals.length ? retirementSpendingGoals : undefined,
          primaryValue: defaultRetirementSpendingAmount
        }
      },
      {
        title: 'Guaranteed Retirement Income',
        label: primaryLabel,
        label2: secondaryLabel,
        value: retirementIncome
      },
      {
        title: 'Annual WDL from Retirement Savings',
        value: annualWithdrawlFromRetirementSavings,
        hideSecondary: true
      }
    ],
    'Spending Inflow & Outflow': [
      {
        title: 'Additional Retirement Spending',
        label: primaryLabel,
        label2: secondaryLabel,
        value: retirementOutflows
      },
      {
        title: 'Retirement Inflows',
        label: primaryLabel,
        label2: secondaryLabel,
        value: retirementInflows
      },
      {
        title: 'Pre-Retirement Outflows from Retirement Assets',
        label: primaryLabel,
        label2: secondaryLabel,
        value: preRetirementSpending
      }
    ],
    'Retirement Savings': [
      {
        title: 'Registered Retirement Savings',
        label: primaryLabel,
        label2: secondaryLabel,
        value: getRegisteredRetirementSavings(accounts, primary, secondary)
      },
      {
        title: 'Non-Registered Retirement Savings',
        label: primaryLabel,
        label2: secondaryLabel,
        value: getNonRegisteredRetirementSavings(accounts, primary, secondary)
      },
      {
        title: 'Tax Free Retirement Savings',
        label: primaryLabel,
        label2: secondaryLabel,
        value: getTaxFreeRetirementSavings(accounts, primary, secondary)
      },
      {
        title: 'Total Retirement Savings',
        label: primaryLabel,
        label2: secondaryLabel,
        value: getTotalRetirementSavings(accounts, primary, secondary)
      }
    ],
    'Education Goals': educationGoals,
    Transactions: transactionGoals,
    Legacy: [
      {
        title: 'Legacy Value',
        hideSecondary: true,
        value: {
          type: 'Legacy: End Value',
          primaryGoal: legacyGoal,
          primaryValue: getLegacyBalance(legacyGoal)
        }
      }
    ]
  };
}
