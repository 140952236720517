import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { SilentRequest } from '@azure/msal-browser';
import * as Sentry from '@sentry/react';
import { AppLayout, getAccessAndIdToken, Loader, useAcquireToken } from '@qwealth/qcore';
import { QContext } from '@qwealth/qdata';

import configureAxios from './services/axiosService';
import { loadHousehold } from './data/actions/household';
import { getQBias } from './data/actions/householdQbias';
import { ReportSummary } from './components/Reports/Summary';
import { ReportSummary as QScoreReportSummary } from 'components/QScoreReport/Summary';
import { initializeAxios as initAxiosAction } from './data/actions/axios';
import { loadUser } from './data/actions/user';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Layout from './components/Layout';

const Intro = lazy(() => import('./components/Intro'));
const Household = lazy(() => import('./components/Household'));
const NetWorth = lazy(() => import('./components/NetWorth'));
const Values = lazy(() => import('./components/Values'));
const FunctionOfAssets = lazy(() => import('./components/FunctionOfAssets'));
const BaselinePlan = lazy(() => import('./components/BaselinePlan'));
const Flinks = lazy(() => import('./components/Flinks/FlinksConfirm'));

const { REACT_APP_QWEALTH_S3_INTEGRATION_USER, REACT_APP_QWEALTH_S3_INTEGRATION_PASSWORD } =
  process.env;

const App: React.FC = () => {
  const { isInProgress, idToken, account } = useAcquireToken();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!REACT_APP_QWEALTH_S3_INTEGRATION_USER || !REACT_APP_QWEALTH_S3_INTEGRATION_PASSWORD) {
      console.warn('Missing configurations for S3 Integration username/password');
    } else {
      // Auth.signIn(REACT_APP_QWEALTH_S3_INTEGRATION_USER, REACT_APP_QWEALTH_S3_INTEGRATION_PASSWORD)
      //   .then(() => console.log('Cognito connected!'))
      //   .catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (idToken && account) {
      Sentry.setUser({ email: account.username });

      QContext.setIdToken(idToken);

      configureAxios().then(() => {
        dispatch(initAxiosAction());
        dispatch(loadHousehold());
        dispatch(getQBias());
        dispatch(loadUser());
      });

      // refresh the token every 20 minutes
      setInterval(() => {
        getAccessAndIdToken({ forceRefresh: true } as SilentRequest)
          .then((auth) => {
            if (auth) {
              QContext.setIdToken(auth.idToken);
            }
          })
          .then(() => Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]))
          .then(([currentUser, currentSession]) =>
            currentUser.refreshSession(
              currentSession.getRefreshToken(),
              (err: any, session: CognitoUserSession) => {
                if (err) {
                  console.error('failed to refresh cognito token', err);
                } else {
                  console.log('Cognito connected and refreshed!', session);
                }
              }
            )
          )
          .catch(console.error);
      }, 20 * 60 * 1000);
    }
  }, [idToken, account, dispatch]);

  if (isInProgress) {
    return (
      <AppLayout alignItems="center">
        <Loader />
      </AppLayout>
    );
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthenticatedTemplate>
        <Routes>
          <Route index element={<Intro />} />
          <Route element={<Layout />}>
            <Route element={<Household />} path="/household" />
            <Route element={<NetWorth />} path="/netWorth" />
            <Route element={<NetWorth legalEntityView />} path="/legalEntities" />
            <Route element={<FunctionOfAssets />} path="/FOA" />
            <Route element={<Values />} path="/values" />
            <Route element={<BaselinePlan/>} path="/baselinePlan" />
            <Route element={<ReportSummary/>} path="/reports" />
            <Route element={<QScoreReportSummary/>} path="/qscoreReport" />
            <Route element={<Flinks/>} path="/flinks-demo" />
          </Route>
        </Routes>
      </AuthenticatedTemplate>
    </Suspense>
  );
};

export default App;
